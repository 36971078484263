import axios from "axios";

const API = axios.create({ baseURL: process.env.REACT_APP_BASE_URL })
// const API = axios.create({ baseURL: "http://192.168.29.181" }) // Incorrect URL

// What's New CRUD API's
export const whatsNewAPI = (headers, time_zone, currentMonth, currentYear) => API.get(`/admin/whatsnews/filter?time_zone=${time_zone}&month=${currentMonth}&year=${currentYear}`, headers)
export const whatsNewListAPI = (pageNumber, searchPost, sortBy, orderBy, headers, time_zone, limit = 10) => API.get(`/admin/whatsnews/list?time_zone=${time_zone}&page_number=${pageNumber}&sortby=${sortBy}&orderby=${orderBy}&search=${searchPost}&size=${limit}`, headers)
 
export const addWhatsNewPostAPI = (formData, headers) => API.post(`/admin/whatsnew/add`, formData, headers)
export const editWhatsNewPostAPI = (post_id, formData, headers) => API.post(`/admin/whatsnew/update/${post_id}`, formData, headers)
export const singleWhatsNewAPI = (post_id, headers) => API.get(`/admin/whatsnew/${post_id}`, headers)
export const deleteWhatsNewPostAPI = (post_id, headers) => API.delete(`/admin/whatsnew/delete/${post_id}`, headers)
