import React, { useEffect, useRef, useState } from 'react';
import EmojiPicker from 'emoji-picker-react';
import Swal from 'sweetalert2';
import io from 'socket.io-client';
import { Oval } from 'react-loader-spinner'
import toast, { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Container from '../Layouts/Container';
import { userChatHistoryAPI, userChatListAPI, uploadImageAPI, chatServiceListAPI, closeChatRequestAPI, deleteChatRequestAPI } from '../../API/chatRequest';
import { sessionExpire } from '../../Scripts/Helper';
import { logoutAdmin } from '../../Action/authAction';
import moment from 'moment-timezone';
import { Image } from 'antd';
let socket;

const ChatScreen = () => {

    const socketUrl = process.env.REACT_APP_BASE_URL
    const imageRef = useRef(null);

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const chatContainerRef = useRef(null);

    const user = useSelector((state) => state.authReducer.authData)
    const authToken = useSelector((state) => state.authReducer.token)

    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const [spinnerLoading, setSpinnerLoading] = useState(false)
    const [showEmojiPicker, setShowEmojiPicker] = useState(false)
    const [showServiceList, setShowServiceList] = useState(true)

    const [userList, setUserList] = useState([])
    const [chatHistory, setChatHistory] = useState([])
    const [serviceList, setServiceList] = useState([])
    const [groupServiceIds, setGroupServiceIds] = useState({ chat_service_id: "", chat_service_equipment_id: "", chat_request_id: "", service_name: "", service_equipment_name: "", status: "" })
    const [sendMsg, setSendMsg] = useState("")
    const [sendImage, setSendImage] = useState("")
    const [cusId, setCustomerId] = useState("")
    const [sendMessageEnable, setsendMessageEnable] = useState(false)

    const [customerInfo, setCustomerInfo] = useState({
        customer_id: "", first_name: "", last_name: "", shopify_customer_id: ""
    })

    // Example using React Hooks
    const [onlineUsers, setOnlineUsers] = useState(new Set());

    const handleInputChange = (event) => {
        const searchValue = event.target.value;
        fetchChatList(searchValue)
    };

    const fetchChatListBySocket = async (search = '') => {
        // setSpinnerLoading(true)

        const config = {
            headers: {
                Authorization: `Bearer ${authToken}`,
                time_zone: userTimeZone,
            }
        }

        await userChatListAPI(config, search)
            .then((response) => {

                setUserList(response ? response.data ? response.data.data : [] : [])

            }).catch((err) => {
                if (err.code == "ERR_NETWORK" || err.code == "ECONNABORTED") {
                    toast.error("Something went wront! please try again later")
                }
                else {
                    let res = sessionExpire(err)
                    toast.error(res.message)

                    if (res.status) {
                        dispatch(logoutAdmin())
                        navigate('/login')
                    }
                }
            })


        // setSpinnerLoading(false)
    }

    // ***************Function to fetch chat list first time****************
    const fetchChatList = async (search = '') => {
        // setSpinnerLoading(true)

        const config = {
            headers: {
                Authorization: `Bearer ${authToken}`,
                time_zone: userTimeZone,
            }
        }

        await userChatListAPI(config, search)
            .then((response) => {

                setUserList(response ? response.data ? response.data.data : [] : [])

                // if (response?.data?.data && chatHistory.length == 0) {
                if (response?.data?.data?.rows[0]?.customer_id && cusId == "") {
                    setCustomerId(response?.data?.data?.rows[0]?.customer_id)
                    fetchChatServiceList(response?.data?.data?.rows[0]?.customer_id)
                    // fetchChatHistory(response.data.data.rows[0].customer_id)
                    setCustomerInfo({
                        customer_id: response.data.data.rows[0].customer_id,
                        first_name: response.data.data.rows[0].first_name,
                        last_name: response.data.data.rows[0].last_name,
                        shopify_customer_id: response.data.data.rows[0].shopify_customer_id,
                    })
                }

            }).catch((err) => {
                console.log("🚀 ~ file: Profile.jsx:80 ~ err:", err)

                if (err.code == "ERR_NETWORK" || err.code == "ECONNABORTED") {
                    toast.error("Something went wront! please try again later")
                }
                else {
                    let res = sessionExpire(err)
                    toast.error(res.message)

                    if (res.status) {
                        dispatch(logoutAdmin())
                        navigate('/login')
                    }
                }
            })


        // setSpinnerLoading(false)
    }

    // ***************Function to fetch chat list first time****************
    const fetchChatServiceList = async (customerId, checkCustomerId = true) => {

        if (customerId == cusId && checkCustomerId == true) {

        } else {

            setServiceList([])
            setCustomerId(customerId)
            setGroupServiceIds({
                chat_service_id: "",
                chat_service_equipment_id: "",
                chat_request_id: "",
                service_name: "",
                service_equipment_name: "",
                status: "",
            })
            // setSpinnerLoading(true)

            const config = {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    time_zone: userTimeZone,
                }
            }

            await chatServiceListAPI(config, customerId)
                .then((response) => {

                    setsendMessageEnable(false)
                    setServiceList(response ? response.data ? response.data : [] : [])

                }).catch((err) => {
                    console.log("🚀 ~ file: Profile.jsx:80 ~ err:", err)

                    if (err.code == "ERR_NETWORK" || err.code == "ECONNABORTED") {
                        toast.error("Something went wront! please try again later")
                    }
                    else {
                        let res = sessionExpire(err)
                        toast.error(res.message)

                        if (res.status) {
                            dispatch(logoutAdmin())
                            navigate('/login')
                        }
                    }
                })

        }
    }

    // ************Function to fecth chat data for specific user*******************
    const fetchChatHistory = async (customerId, chat_service_id, chat_service_equipment_id, socket_id) => {
        // setSpinnerLoading(true)
        setShowServiceList(false)
        setCustomerId(customerId)

        const config = {
            headers: {
                Authorization: `Bearer ${authToken}`,
                time_zone: userTimeZone,
            }
        }

        await userChatHistoryAPI(config, customerId, chat_service_id, chat_service_equipment_id)
            .then((response) => {

                socket.emit('join-room', socket_id);
                setChatHistory(response ? response.data ? response.data.data : [] : [])
                setsendMessageEnable(true)
                // fetchChatListBySocket();

            }).catch((err) => {
                console.log("🚀 ~ file: Profile.jsx:80 ~ err:", err)

                if (err.code == "ERR_NETWORK" || err.code == "ECONNABORTED") {
                    toast.error("Something went wront! please try again later")
                }
                else {
                    let res = sessionExpire(err)
                    toast.error(res.message)

                    if (res.status) {
                        dispatch(logoutAdmin())
                        navigate('/login')
                    }
                }
            })

        // setSpinnerLoading(false)
    }

    // *************Function to Upload chat image to server**************
    const uploadImage = async (image) => {

        const config = {
            headers: {
                Authorization: `Bearer ${authToken}`,
                time_zone: userTimeZone,
            }
        }

        if (imageRef.current) {
            imageRef.current.value = null;
        }
        const formData = new FormData();
        formData.append("image", image)
        await uploadImageAPI(config, formData, cusId).then((response) => {
            console.log(response.data.data)

            sendMessageToCustomer(chatHistory?.customerInfo.id, chatHistory?.rows[0].chat_request_id, response.data.data.imageName)


        }).catch((err) => {
            console.log("🚀 ~ file: Profile.jsx:80 ~ err:", err)

            if (err.code == "ERR_NETWORK" || err.code == "ECONNABORTED") {
                toast.error("Something went wront! please try again later")
            }
            else {
                let res = sessionExpire(err)
                toast.error(res.message)

                if (res.status) {
                    dispatch(logoutAdmin())
                    navigate('/login')
                }
            }
        })

    }

    // ***************Function to send message to customer*************
    const sendMessageToCustomer = async (customerId, requestId, imageName = '') => {


        const currentDateTime = moment.utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

        socket.emit('send-message-customer-admin', {
            sent_by: "A",
            is_read: true,
            message: sendMsg,
            image_url: imageName,
            customer_id: customerId,
            chat_request_id: groupServiceIds.chat_request_id,
            chat_service_id: groupServiceIds.chat_service_id,
            chat_service_equipment_id: groupServiceIds.chat_service_equipment_id,
            created_at: currentDateTime,
            updated_at: currentDateTime,
            deleted_at: null
        })

        setSendMsg("")
        setSendImage("")

        fetchChatList()

    }

    // *********** Funchtion to Close chat ***********
    const closeChat = (e, id) => {
        e.preventDefault();

        Swal.fire({
            title: 'Confirm close request?',
            text: "Do you really want to close this request?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, close it!'
        }).then(async function (result) {
            if (result.value) {
                setSpinnerLoading(true)

                const config = {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                        time_zone: userTimeZone,
                    }
                }

                await closeChatRequestAPI(config, id)
                    .then((res) => {
                        Swal.fire('Request Closed!', 'Request has been closed successfully.', 'success');
                        toast.success(res.data.message)

                        setShowServiceList(true); setChatHistory([])

                        setGroupServiceIds({
                            chat_service_id: "",
                            chat_service_equipment_id: "",
                            chat_request_id: "",
                            service_name: "",
                            service_equipment_name: "",
                            status: "",
                        })
                        fetchChatServiceList(cusId, false)

                    }).catch((err) => {
                        console.log("🚀 ~ file: Profile.jsx:80 ~ err:", err)
                        if (err.code == "ERR_NETWORK") {
                            toast.error("Something went wront! please try again later")
                        }
                        else {
                            toast.error(err.response.data.message)
                        }
                    })
                setSpinnerLoading(false)
            }
        })
    }


    //**************** Function to Delete Request ********************/

    const deleteChatRequest = async (e, id) => {
        e.preventDefault();

        Swal.fire({
            title: 'Confirm delete request?',
            text: "Do you really want to delete this request?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!'
        }).then(async function (result) {
            if (result.value) {
                setSpinnerLoading(true)

                const config = {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                        time_zone: userTimeZone,
                    }
                }

                await deleteChatRequestAPI(config, id)
                    .then((res) => {
                        Swal.fire('Request Deleted!', 'Request has been deleted successfully.', 'success');
                        toast.success(res.data.message)


                        setShowServiceList(true); setChatHistory([])

                        setGroupServiceIds({
                            chat_service_id: "",
                            chat_service_equipment_id: "",
                            chat_request_id: "",
                            service_name: "",
                            service_equipment_name: "",
                            status: "",
                        })
                        fetchChatServiceList(cusId, false)
                        fetchChatList()

                    }).catch((err) => {
                        console.log("Error: ", err);
                        if (err.code == "ERR_NETWORK") {
                            toast.error("Something went wrong! Please try again later.")
                        }
                        else {
                            toast.error(err.response.data.message)
                        }
                    })
                setSpinnerLoading(false)
            }
        })
    }
    //**************** Function to Delete Request ********************/


    // ***********Funchtion to scroll chat to bottom***********
    const scrollToBottom = () => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        !user && navigate('/login')
        fetchChatList()
    }, []);

    useEffect(() => {
        !user && navigate('/login')
        scrollToBottom();
    }, [chatHistory]);


    useEffect(() => {

        socket = io(socketUrl);

        socket.on('connect', () => {
            console.log('Connected to the Socket.io server');
        });

        // Replace 'room1' with the actual room name

        socket.on('message', (data) => {

            // if(cusId == data.customer_id) {
            //     fetchChatServiceList(data.customer_id,false)
            // }

            fetchChatListBySocket();
        })


        socket.on('send-message-customer-admin', (data) => {
            setChatHistory(prevChatHistory => {
                if (data.customer_id == prevChatHistory?.customerInfo?.id) {
                    return { ...prevChatHistory, rows: [data, ...prevChatHistory.rows] };
                } else {
                    return prevChatHistory;
                }
            });
            // fetchChatListBySocket()
        });

        socket.on('user-online', (userId) => {
            console.log(userId)
            setOnlineUsers(prev => new Set(prev).add(parseInt(userId)));
        });

        socket.on('user-offline', (userId) => {
            console.log("user-offline", userId)
            setOnlineUsers(prev => {
                const updatedSet = new Set(prev);
                updatedSet.delete(userId);
                return updatedSet;
            });
        });

        return () => {

            socket.off('user-online');
            socket.off('user-offline');
            socket.disconnect();
        };
    }, []);

    let text = `ProductName: 4 lbs \n ProductSku: 47246960`

    return (
        <>
            {user &&
                <Container>
                    <div className="nk-content mt-5 ">
                        <div className="container-fluid">
                            <div className="nk-content-inner">
                                <div className="nk-content-body">

                                    <div className="nk-block-head nk-block-head-sm mt-3">
                                        <div className="nk-block-between">
                                            <div className="nk-block-head-content">
                                                <h4 className="nk-block-title page-title">The Pool Store /
                                                    <span> Service Request</span>
                                                </h4>
                                            </div>{/* .nk-block-head-content */}
                                        </div>{/* .nk-block-between */}
                                    </div>{/* .nk-block-head */}



                                    {spinnerLoading
                                        ? <div className='vh-100 flex justify-center items-center' style={{ height: "60vh" }}>
                                            <Oval
                                                height="100"
                                                width="100"
                                                color='var(--ps-main)'
                                                secondaryColor="var(--ps-main)"
                                                ariaLabel='oval-loading'
                                                strokeWidth={4}
                                                strokeWidthSecondary={4}
                                                visible={true}
                                            />
                                        </div>
                                        : <div className="nk-block-head nk-block-head-sm mt-3">
                                            <div className="nk-block-between">
                                                <div className="nk-block-head-content w-100 row">

                                                    <div className="col-12 col-md-12">

                                                        <div className="nk-content-inner">
                                                            <div className="nk-content-body">
                                                                <div className="nk-chat">

                                                                    <div className="nk-chat-aside">
                                                                        <div className="nk-chat-aside-body" data-simplebar>
                                                                            <div className="nk-chat-aside-search" style={{ paddingLeft: '3px', paddingRight: '10px', }}>
                                                                                <h6 className="title overline-title-alt">Conversations with Customers</h6>
                                                                                <div className="form-group">
                                                                                    <div className="form-control-wrap leftSearchTxt">
                                                                                        <div className="form-icon form-icon-left"><em className="icon ni ni-search"></em></div>
                                                                                        <input type="text" className="form-control form-round" id="default-03" placeholder="Search" onChange={handleInputChange} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="nk-chat-list" style={{ paddingLeft: '0px', }}>
                                                                                <ul className="chat-list">
                                                                                    {userList?.rows?.length > 0
                                                                                        ? userList.rows.map((data) => {
                                                                                            const isOnline = onlineUsers.has(parseInt(data.customer_id));

                                                                                            return <li key={data.chat_id} className="chat-item">
                                                                                                <a className="chat-link chat-open" onClick={e => {
                                                                                                    fetchChatServiceList(data.customer_id)
                                                                                                    setCustomerInfo({
                                                                                                        customer_id: data.customer_id,
                                                                                                        first_name: data.first_name,
                                                                                                        last_name: data.last_name,
                                                                                                        shopify_customer_id: data.shopify_customer_id,
                                                                                                    })
                                                                                                    setShowServiceList(true)
                                                                                                    setChatHistory([])
                                                                                                    // fetchChatHistory(data.customer_id)
                                                                                                    setSendMsg("")
                                                                                                    setSendImage("")
                                                                                                }}>
                                                                                                    <div className="chat-media user-avatar">
                                                                                                        {data.image_url
                                                                                                            // ? <span><img src={data.image_url} alt="" /></span>
                                                                                                            ? <span><img src="images/sample-user.jpg" alt="" /></span>
                                                                                                            : <span><img src="images/sample-user.jpg" alt="" /></span>
                                                                                                        }
                                                                                                        <span className={`status dot dot-lg ${isOnline ? 'dot-success' : 'dot-gray'}`}>{isOnline}ss</span>
                                                                                                    </div>
                                                                                                    <div className="chat-info">
                                                                                                        <div className="chat-from">
                                                                                                            <div className="name">{data.first_name ? data.first_name : "--"} {data.last_name}</div>
                                                                                                            <span className="time badge badge-dim bg-warning">
                                                                                                                {
                                                                                                                    moment.utc(moment()).tz(userTimeZone).format('YYYY-MM-DD') === moment(data.created_at).format("YYYY-MM-DD")
                                                                                                                        ? moment(data.created_at).format("hh:mm A")
                                                                                                                        : moment(data.created_at).format("YYYY-MM-DD")
                                                                                                                }
                                                                                                            </span>
                                                                                                        </div>
                                                                                                        <div className="chat-context">
                                                                                                            <div className="text">
                                                                                                                <p>{data.service_name} <br />({data.service_equipment_name})</p>
                                                                                                            </div>
                                                                                                            <div className="status delivered d-flex justify-content-center align-items-center me-3">
                                                                                                                {data.unread
                                                                                                                    ? <span className='px-1' style={{ backgroundColor: "#ed1b24", color: "white", borderRadius: "50%", paddingTop: "1px" }}><b>{data.unread}</b></span>
                                                                                                                    : ''
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </a>
                                                                                            </li>
                                                                                        })
                                                                                        : <div className='d-flex justify-content-center align-items-center'>
                                                                                            <h6>No Chat available!</h6>
                                                                                        </div>
                                                                                    }
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="nk-chat-body profile-shown">
                                                                        <div className="nk-chat-head" style={{ padding: "6px 2px" }}>
                                                                            <ul className="nk-chat-head-info">
                                                                                <li className="nk-chat-body-close"><a href="#" className="btn btn-icon btn-trigger nk-chat-hide ms-n1"><em className="icon ni ni-arrow-left"></em></a></li>
                                                                                <li className="nk-chat-head-user">
                                                                                    <Link to={`/customer-detail/${customerInfo?.shopify_customer_id}`}>
                                                                                        <div className="user-card chatUserOwn">
                                                                                            <div className="user-avatar">
                                                                                                <span><img src="images/sample-user.jpg" alt="" /></span>
                                                                                            </div>
                                                                                            <div className="user-info">
                                                                                                <div className="lead-text" style={{ display: "flex", alignItems: "center" }}>
                                                                                                    {customerInfo && customerInfo.first_name} {customerInfo && customerInfo.last_name}
                                                                                                    {customerInfo && (
                                                                                                        <span className={`status dot dot-lg ${onlineUsers.has(parseInt(customerInfo.customer_id)) ? 'dot-success' : 'dot-gray'}`} style={{ margin: "4px" }}></span>
                                                                                                    )}
                                                                                                </div>

                                                                                                {serviceList?.customerInfo && (

                                                                                                    <>

                                                                                                        {/* <span className={`status dot dot-lg ${onlineUsers.has(parseInt(serviceList.customerInfo.id)) ? 'dot-success' : 'dot-gray'}`}></span> */}
                                                                                                        {/* <span>{onlineUsers.has(serviceList.customerInfo.id) ? ' Online' : ' Offline'}</span> */}

                                                                                                        <span>{groupServiceIds.service_name ? groupServiceIds.service_name + ' > ' + groupServiceIds.service_equipment_name : ''}</span>
                                                                                                    </>
                                                                                                )}


                                                                                            </div>
                                                                                        </div>
                                                                                    </Link>
                                                                                </li>
                                                                            </ul>
                                                                            <ul className="nk-chat-head-tools rightTxtChatProfile">
                                                                                {!showServiceList &&
                                                                                    <button className='btn btn-sm btn-primary'
                                                                                        onClick={() => {
                                                                                            setShowServiceList(true); setChatHistory([])

                                                                                            setGroupServiceIds({
                                                                                                chat_service_id: "",
                                                                                                chat_service_equipment_id: "",
                                                                                                chat_request_id: "",
                                                                                                service_name: "",
                                                                                                service_equipment_name: "",
                                                                                                status: "",
                                                                                            })
                                                                                            fetchChatServiceList(cusId, false)

                                                                                        }}>View Service List</button>
                                                                                }
                                                                                {/* <li><a href="#" className="btn text-primary newCustomer">New Customer</a></li> */}
                                                                                {/* <li><a href="#" className="btn text-primary viewProfile">View Profile</a></li> */}
                                                                                {/* <li><em className="icon ni ni-bag"></em> 0 Orders</li> */}
                                                                            </ul>
                                                                            {/* <div className="nk-chat-head-search">
                                                                                <div className="form-group">
                                                                                    <div className="form-control-wrap">
                                                                                        <div className="form-icon form-icon-left"><em className="icon ni ni-search"></em></div>
                                                                                        <input type="text" className="form-control form-round" id="chat-search" placeholder="Search in Conversation" />
                                                                                    </div>
                                                                                </div>
                                                                            </div> */}
                                                                        </div>


                                                                        {/* <SimpleBar ref={chatContainerRef} style={{ maxHeight: '100%', overflowX: 'hidden' }}> */}
                                                                        <div className="nk-chat-panel" ref={chatContainerRef} style={{ borderBottom: "1px solid #e5e9f2", borderRight: "1px solid #e5e9f2", padding: "6px" }}>

                                                                            {showServiceList && serviceList?.data?.length > 0 &&
                                                                                <div className="chat-service-list" >
                                                                                    <div className="chat-content" style={{ width: "100%" }}>
                                                                                        <span className='' style={{ fontWeight: "bold", color: "black" }}>SERVICE REQUEST LIST</span>
                                                                                        <div className="chat-bubbles">
                                                                                            <div className="chat-bubble" style={{ flexDirection: "column" }}>
                                                                                                {
                                                                                                    serviceList.data.map((data, index) => (
                                                                                                        <div className="card card-bordered card-preview" style={{ width: "100%", padding: "0px", marginTop: "5px" }} key={data.chat_request_id}>
                                                                                                            <div className="card-inner" style={{ padding: "0px" }}>
                                                                                                                <div id="accordion" className="accordion">
                                                                                                                    <div className="accordion-item">
                                                                                                                        <a href="#" className="accordion-head" data-bs-toggle="collapse" data-bs-target={`#${data.chat_request_id}accordion-item-${index}`}>
                                                                                                                            <h6 className="title">
                                                                                                                                {data.service_name} ({data.service_equipment_name})
                                                                                                                                {/* {data.created_at} */}

                                                                                                                                {moment.utc(moment()).tz(userTimeZone).format('YYYY-MM-DD') === moment(data.created_at).format("YYYY-MM-DD") && (
                                                                                                                                    <span> {moment(data.created_at).format("YYYY-MM-DD")} </span>
                                                                                                                                )}


                                                                                                                                {data.unread > 0 && <span className='px-1 ms-2' style={{ backgroundColor: "#ed1b24", color: "white", borderRadius: "50%", paddingTop: "1px" }}>{data.unread}</span>}

                                                                                                                            </h6>
                                                                                                                            {/* <div className="">{data.unread > 0 && <span className='px-1' style={{ backgroundColor: "#ed1b24", color: "white", borderRadius: "50%", paddingTop: "1px" }}><b>{data.unread}</b></span>}</div> */}
                                                                                                                            <span className="accordion-icon"></span>
                                                                                                                        </a>
                                                                                                                        <div className={`accordion-body collapse ${index === 0 ? 'show' : ''}`} id={`${data.chat_request_id}accordion-item-${index}`} data-bs-parent="#accordion"> <div className="accordion-inner">
                                                                                                                            <p style={{ margin: "0px" }}><b>Customer Name :</b> {data.customer_name}</p>
                                                                                                                            <p style={{ margin: "0px" }}><b> Phone Number :</b> {data.request_phone_number}</p>
                                                                                                                            <p style={{ margin: "0px" }}><b> Service Address :</b> {data.service_address}</p>

                                                                                                                            <div style={{ float: "right", margin: "9px 0px" }}>
                                                                                                                                <button className="btn btn-sm btn-primary me-1"
                                                                                                                                    onClick={() => {
                                                                                                                                        setGroupServiceIds({
                                                                                                                                            chat_service_id: data.chat_service_id,
                                                                                                                                            chat_service_equipment_id: data.chat_service_equipment_id,
                                                                                                                                            chat_request_id: data.chat_request_id,
                                                                                                                                            service_name: data.service_name,
                                                                                                                                            service_equipment_name: data.service_equipment_name,
                                                                                                                                            status: data.status,
                                                                                                                                        })
                                                                                                                                        fetchChatHistory(data.customer_id, data.chat_service_id, data.chat_service_equipment_id, data.socket_id)
                                                                                                                                    }}
                                                                                                                                >{+data.status !== 2 ? "Open Chat" : "View Chat"}</button>
                                                                                                                                {+data.status !== 2 &&
                                                                                                                                    <button className="btn btn-sm btn-danger me-1"
                                                                                                                                        onClick={(e) => {
                                                                                                                                            closeChat(e, data.chat_request_id)
                                                                                                                                        }}
                                                                                                                                    >Close Request </button>
                                                                                                                                }
                                                                                                                                {/* {+data.status !== 2 && */}
                                                                                                                                <button className="btn btn-sm btn-danger"
                                                                                                                                    onClick={(e) => {

                                                                                                                                        deleteChatRequest(e, data.chat_request_id)
                                                                                                                                    }}
                                                                                                                                > Delete Request</button>
                                                                                                                                {/* } */}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    ))
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            }


                                                                            {!showServiceList && chatHistory?.rows?.length > 0
                                                                                && [...chatHistory.rows].reverse().map((data) => {
                                                                                    return <div key={data.chat_id} className={data.sent_by == "C" ? "chat is-you" : "chat is-me"}>
                                                                                        <div className="chat-content">
                                                                                            <div className="chat-bubbles">
                                                                                                <div className="chat-bubble">
                                                                                                    {data.image_url &&
                                                                                                        // <img src={data.image_url} style={{ height: "100px" }} alt="" />
                                                                                                        <Image src={data.image_url} style={{ height: "100px" }} alt="" />
                                                                                                    }
                                                                                                </div>
                                                                                                <div className="chat-bubble">
                                                                                                    {data.message && <div className="chat-msg" style={{ whiteSpace: "pre-line" }}> {data.message} </div>}
                                                                                                </div>
                                                                                            </div>
                                                                                            <ul className="chat-meta">
                                                                                                <li>
                                                                                                    {
                                                                                                        moment.utc(moment()).tz(userTimeZone).format('YYYY-MM-DD') === moment(data.created_at).format("YYYY-MM-DD")
                                                                                                            ? moment(data.created_at).format("HH:mm A")
                                                                                                            : moment(data.created_at).format("YYYY-MM-DD")
                                                                                                    }
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                })
                                                                            }
                                                                            {showEmojiPicker &&
                                                                                <div className="chat is-me" style={{ position: "absolute", bottom: "15%", right: "20%", boxShadow: "0 1px 20px black" }}>
                                                                                    <EmojiPicker
                                                                                        onEmojiClick={(e) => { setSendMsg((prevMessage) => prevMessage + e.emoji) }}
                                                                                        skinTonesDisabled={false}
                                                                                        searchDisabled={false}
                                                                                    />
                                                                                </div>
                                                                            }

                                                                        </div>


                                                                        {!showServiceList && sendMessageEnable && +groupServiceIds.status !== 2 &&
                                                                            <div className="nk-chat-editor">
                                                                                <div className="nk-chat-editor-upload  ms-n1">
                                                                                    <a onClick={e => !sendImage && imageRef.current.click()} className="btn btn-sm btn-icon btn-trigger text-primary toggle-opt" data-target="chat-upload"><em className="icon ni ni-img-fill"></em></a>
                                                                                    <input
                                                                                        className='d-none'
                                                                                        type="file"
                                                                                        ref={imageRef}
                                                                                        onChange={e => uploadImage(e.target.files[0])}
                                                                                    ></input>
                                                                                </div>
                                                                                <div className="nk-chat-editor-form">
                                                                                    <div className="form-control-wrap">
                                                                                        {/* <textarea className="form-control form-control-simple no-resize" onKeyDown={e => e.key === 'Enter' && sendMessageToCustomer(chatHistory?.customerInfo.id)} rows="1" id="default-textarea" value={sendMsg} onChange={e => setSendMsg(e.target.value)} placeholder="Type your message...">
                                                                                </textarea> */}
                                                                                        <input className="form-control form-control-simple no-resize" onKeyDown={e => e.key === 'Enter' && sendMsg && sendMessageToCustomer(chatHistory?.customerInfo.id, chatHistory?.rows[0].chat_request_id)} id="default-textarea" value={sendMsg} onChange={e => setSendMsg(e.target.value)} placeholder="Type your message..." style={{ padding: "0px" }}>
                                                                                        </input>
                                                                                    </div>
                                                                                </div>
                                                                                <ul className="nk-chat-editor-tools g-2">
                                                                                    <li>
                                                                                        <a
                                                                                            onClick={() => {
                                                                                                setShowEmojiPicker(!showEmojiPicker); scrollToBottom()
                                                                                            }}
                                                                                            className="btn btn-sm btn-icon btn-trigger text-primary">
                                                                                            <em className="icon ni ni-happyf-fill"></em>
                                                                                        </a>
                                                                                    </li>

                                                                                    <li>
                                                                                        <button
                                                                                            className="btn btn-primary btn-icon"
                                                                                            onClick={e => {
                                                                                                sendMessageToCustomer(chatHistory?.customerInfo.id, chatHistory?.rows[0].chat_request_id); setShowEmojiPicker(false)
                                                                                            }}
                                                                                            style={{ paddingLeft: '10px', paddingRight: '10px', }}
                                                                                            disabled={!sendMsg}
                                                                                        >
                                                                                            Send
                                                                                            {/* <em className="icon ni ni-send-alt"></em> */}
                                                                                        </button>
                                                                                    </li>

                                                                                    <li>
                                                                                        <button
                                                                                            className="btn btn-danger btn-icon"
                                                                                            onClick={e => {
                                                                                                closeChat(e, groupServiceIds.chat_request_id)
                                                                                            }}
                                                                                            style={{ paddingLeft: '10px', paddingRight: '10px', }}>
                                                                                            Close
                                                                                            {/* <em className="icon ni ni-cross-c"></em> */}
                                                                                        </button>
                                                                                    </li>

                                                                                </ul>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>{/* .nk-block-head-content */}
                                            </div>{/* .nk-block-between */}
                                        </div>
                                    }


                                </div>
                            </div>
                        </div>
                    </div>
                </Container >
            }
        </>
    );
}

export default ChatScreen;