import React, { useEffect, useRef, useState } from 'react';
import { Oval } from 'react-loader-spinner'
import Swal from 'sweetalert2';
import toast, { Toaster } from 'react-hot-toast';
import Container from '../Layouts/Container';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { logoutAdmin } from '../../Action/authAction';
import { sessionExpire } from '../../Scripts/Helper';
import { deleteDiscountBannerApi, discountBannerApi } from '../../API/DiscountsRequest';

const Discount = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const user = useSelector((state) => state.authReducer.authData)
    const authToken = useSelector((state) => state.authReducer.token)
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const [spinnerLoading, setSpinnerLoading] = useState(false)
    const [discountData, setDiscountData] = useState([])

    const fetchDiscountBanners = async () => {
        setSpinnerLoading(true)
        const config = {
            headers: { Authorization: `Bearer ${authToken}` }
        }
        await discountBannerApi(config)
            .then((response) => {
                setDiscountData(response ? response.data ? response.data.data : [] : [])
            }).catch((err) => {
                console.log("🚀 ~ file: Profile.jsx:80 ~ err:", err)

                if (err.code == "ERR_NETWORK") {
                    toast.error("Something went wront! please try again later")
                }
                else {
                    let res = sessionExpire(err)
                    toast.error(res.message)

                    if (res.status) {
                        dispatch(logoutAdmin())
                        navigate('/login')
                    }
                }
            })
        setSpinnerLoading(false)
    }

    const handleDeleteDiscount = async (e, id) => {
        e.preventDefault();

        Swal.fire({
            title: 'Confirm delete?',
            text: "Do you really want to delete this screen?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!'
        }).then(async function (result) {
            if (result.value) {
                setSpinnerLoading(true)

                const config = {
                    headers: { Authorization: `Bearer ${authToken}` }
                };
                await deleteDiscountBannerApi(config, id)
                    .then((res) => {
                        Swal.fire('Deleted!', 'Post has been deleted successfully.', 'success');
                        toast.success(res.data.message)
                        fetchDiscountBanners()
                    }).catch((err) => {
                        console.log("🚀 ~ file: Profile.jsx:80 ~ err:", err)
                        if (err.code == "ERR_NETWORK") {
                            toast.error("Something went wront! please try again later")
                        }
                        else {
                            toast.error(err.response.data.message)
                        }
                    })
                setSpinnerLoading(false)
            }
        })
    }

    useEffect(() => {
        !user && navigate('/login')
        fetchDiscountBanners()
    }, []);

    return (
        <>
            <Toaster position="top-right" reverseOrder={false} />
            <Container>
                <div className="nk-content mt-10">
                    <div className="container">
                        <div className="nk-content-inner">
                            <div className="nk-content-body">
                                <div className="nk-block-head nk-block-head-sm mt-3">
                                    <div className="nk-block-between">
                                        <div className="nk-block-head-content">
                                            <h4 className="nk-block-title page-title">The Pool Store /
                                                <span>Discount Screen</span>
                                            </h4>
                                        </div>
                                    </div>
                                </div>


                                <div className="nk-block">
                                    <div className="row g-gs">
                                        <div className="col-md-3">
                                            <div className="circleChartFlex">
                                                <div className="">
                                                    <span>Discounts activity</span>
                                                    <h6>Showing all {discountData?.count} Banners</h6>
                                                </div>

                                                <div className="d-flex">
                                                </div>

                                            </div>
                                        </div>

                                        {/* <div className="col-md-6">
                                            <div className="form-control-wrap searchBarTable">
                                                <div className="form-icon form-icon-right">
                                                    <em className="icon ni ni-search"></em></div>
                                                <input type="text" className="form-control" id="fv-email" name="fv-email" placeholder='Search Here...' />
                                            </div>
                                        </div> */}

                                        {/* <div className="col-md-3"> */}
                                        <div className="col-md-9">
                                            <div className="form-control-wrap searchBarTable">
                                                <Link to={"/add-discount"} className="btn btn-primary lightBlue d-md-inline-flex fRight">Add New Screen</Link>
                                            </div>
                                        </div>

                                    </div>{/* .row */}
                                </div>



                                {spinnerLoading
                                    ? <div className='flex justify-center items-center' style={{ height: "65vh" }}>
                                        <Oval
                                            height="100"
                                            width="100"
                                            color='var(--ps-main)'
                                            secondaryColor="var(--ps-main)"
                                            ariaLabel='oval-loading'
                                            strokeWidth={4}
                                            strokeWidthSecondary={4}
                                            visible={true}
                                        />
                                    </div>
                                    : <div className="nk-block">
                                        <div className="row g-gs">


                                            <div className="col-md-12">
                                                <div className="tab-content">
                                                    <div className="tab-pane active show" id="tabItem1" role="tabpanel">
                                                        <div className="row">
                                                            {discountData?.rows?.length > 0 &&
                                                                discountData.rows.map(data => {

                                                                    return <div key={data.id} className="col-lg-3">
                                                                        <div className="card card-bordered rounded-5">
                                                                            <div className="product-thumb d-flex justify-content-center m-1">
                                                                                <img src={data.image_icon} alt="" style={{ minWidth: "200px", minHeight: "200px", maxWidth: "200px", maxHeight: "200px" }} />
                                                                            </div>
                                                                            <div className="card-inner p-1 mx-2">
                                                                                <div className="d-flex justify-content-between">
                                                                                    <h6 className="product-title fs-15px m-0">{data.title}</h6>
                                                                                    <div className="drodown">
                                                                                        <a href="#" className="" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                            <em role='button' className="icon ni ni-more-v" />
                                                                                        </a>
                                                                                        <div className="dropdown-menu dropdown-menu-end">
                                                                                            <ul className="link-list-opt no-bdr ">
                                                                                                <li>
                                                                                                    <Link to={`/edit-discount/${data.id}`} role='button' className='d-flex justify-content-around align-item-center'>
                                                                                                        <em className="icon ni ni-edit me-2" style={{ fontSize: "1.1rem" }} />
                                                                                                        <span style={{ fontSize: "0.8rem" }}>Edit</span>
                                                                                                    </Link>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <a onClick={e => handleDeleteDiscount(e, data.id)} role='button' className='d-flex justify-content-around align-item-center text-danger'>
                                                                                                        <em className="icon ni ni-trash" style={{ fontSize: "1.2rem" }} />
                                                                                                        <span style={{ fontSize: "0.8rem" }}>Delete</span>
                                                                                                    </a>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {/* <p className='fs-12px mt-1'>{data.description}</p>
                                                                                <p className='fs-12px m-0 mt-1'><b>Start Date:</b> {data.start_date}</p>
                                                                                <p className='fs-12px m-0 mt-1'><b>End Date:</b> {data.end_date}</p> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                })
                                                            }
                                                        </div>
                                                    </div>


                                                    <div className="tab-pane" id="tabItem2" role="tabpanel">
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <h1>TAB 2</h1>
                                                                {discountData.length == 0
                                                                    && <div className='mt-5 d-flex justify-content-center align-item-center'>
                                                                        <h6>No Data Available</h6>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="tab-pane" id="tabItem3" role="tabpanel">
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <h1>TAB 3</h1>
                                                                {discountData.length == 0
                                                                    && <div className='mt-5 d-flex justify-content-center align-item-center'>
                                                                        <h6>No Data Available</h6>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                </div >

            </Container>
        </>
    );
}

export default Discount;
