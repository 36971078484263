import React, { useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { Oval } from 'react-loader-spinner'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Container from '../Layouts/Container';
import { Image, ImageProps } from 'antd';
import { allCustomerList } from '../../API/customerRequest';
import { sessionExpire } from '../../Scripts/Helper';
import { useState } from 'react';
import { logoutAdmin } from '../../Action/authAction';
import { deletePerkAPI, poolPerksAPI } from '../../API/PoolPerkRequest';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';
import ReactPaginate from 'react-paginate';

const imageStyle = {
    background: 'white',
};
const PoolPerks = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const user = useSelector((state) => state.authReducer.authData)
    const authToken = useSelector((state) => state.authReducer.token)
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const [currentView, setCurrentView] = useState(false)


    const [spinnerLoading, setSpinnerLoading] = useState(false)
    const [poolPerkData, setPoolPerkData] = useState([])

    const [nextPage, setNextPage] = useState("")
    const [searchCustomer, setSearchCustomer] = useState("")
    const [sortBy, setSortBy] = useState("")
    const [orderBy, setOrderyBy] = useState("")
    const [activeWhatsNew, setActiveWhatsNew] = useState([])
    const [activePostData, setActivePostData] = useState({})
    const [activePageCount, setActivePageCount] = useState(0)

    const [pageNumber, setPagenumber] = useState(1)



    const handlePageChange = async (d) => {
        window.scrollTo(0, 0);
        var CurrentPage = d.selected + 1;
        setPagenumber(CurrentPage)

    }



    let fetchAllPerksList = async () => {
        setSpinnerLoading(true);

        const config = {
            headers: {
                Authorization: `Bearer ${authToken}`,
                time_zone: userTimeZone,
            }
        };

        await poolPerksAPI(config,pageNumber)
            .then((response) => {
                setPoolPerkData(response ? response.data ? response.data.data : [] : []);
                setActivePostData({
                    count: response.data.data.count,
                    currentPage: response.data.data.currentPage,
                    totalPages: response.data.data.totalPages,
                })
                setActivePageCount(Math.ceil(response.data.data.count / 10))
            }).catch((err) => {
                console.error("Error fetching perk history:", err);

                if (err.code === "ERR_NETWORK") {
                    toast.error("Something went wrong! Please try again later.");
                } else {
                    let res = sessionExpire(err);
                    toast.error(res.message);

                    if (res.status) {
                        dispatch(logoutAdmin());
                        navigate('/login');
                    }
                }
            });

        setSpinnerLoading(false);
    };

    // let fetchAllPerksList = async () => {
    //     setSpinnerLoading(true)

    //     const config = {
    //         headers: {
    //             Authorization: `Bearer ${authToken}`,
    //             time_zone: userTimeZone,
    //         }
    //     }

    //     if (currentView) {

    //         await poolPerksAPI(config)
    //             .then((response) => {

    //                 setPoolPerkData(response ? response.data ? response.data.data : [] : [])

    //             }).catch((err) => {
    //                 console.log("🚀 ~ .then ~ err:", err)

    //                 if (err.code == "ERR_NETWORK") {
    //                     toast.error("Something went wront! please try again later")
    //                 }
    //                 else {
    //                     let res = sessionExpire(err)
    //                     console.log("🚀 ~ .then ~ err:", err)
    //                     toast.error(res.message)

    //                     if (res.status) {
    //                         dispatch(logoutAdmin())
    //                         navigate('/login')
    //                     }
    //                 }
    //             })
    //     }
    //     else {
    //         await poolPerksAPI(config)
    //             .then((response) => {

    //                 setPoolPerkData(response ? response.data ? response.data.data : [] : [])

    //             }).catch((err) => {
    //                 console.log("🚀 ~ .then ~ err:", err)

    //                 if (err.code == "ERR_NETWORK") {
    //                     toast.error("Something went wront! please try again later")
    //                 }
    //                 else {
    //                     let res = sessionExpire(err)
    //                     console.log("🚀 ~ .then ~ err:", err)
    //                     toast.error(res.message)

    //                     if (res.status) {
    //                         dispatch(logoutAdmin())
    //                         navigate('/login')
    //                     }
    //                 }
    //             })

    //     }
    //     setSpinnerLoading(false)
    // }
    useEffect(() => {
        fetchAllPerksList();
    }, [pageNumber]);

    const handleDeletePerk = async (e, id) => {

        e.preventDefault();
        Swal.fire({
            title: 'Confirm delete?',
            text: "Do you really want to delete this perk?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!'
        }).then(async function (result) {
            if (result.value) {
                setSpinnerLoading(true)

                const config = {
                    headers: { Authorization: `Bearer ${authToken}` }
                };
                await deletePerkAPI(config, id)
                    .then((res) => {
                        Swal.fire('Deleted!', 'Perk has been deleted successfully.', 'success');
                        toast.success(res.data.message)
                        fetchAllPerksList()
                    }).catch((err) => {
                        console.log("🚀 ~ file: Profile.jsx:80 ~ err:", err)
                        if (err.code == "ERR_NETWORK" || err.code == "ECONNABORTED") {
                            toast.error("Something went wront! please try again later")
                        }
                        else {
                            toast.error(err.response.data.message)
                        }
                    })
                setSpinnerLoading(false)
            }
        })
    }

    // useEffect(() => {
    //     !user && navigate('/login')
    //     fetchAllPerksList()
    // }, [])
    // }, [nextPage, searchCustomer, sortBy, orderBy])



    const goToHistoryPage = () => {
        navigate('/pool-perks-history');
    };

    return (
        <>
            <Toaster position="top-right" reverseOrder={false} />
            {user &&
                <Container>
                    <div className="nk-content mt-5 ">
                        <div className="container-fluid">
                            <div className="nk-content-inner">
                                <div className="nk-content-body">

                                    <div className="nk-block-head nk-block-head-sm mt-3">
                                        <div className="nk-block-between">
                                            <div className="nk-block-head-content">
                                                <h4 className="nk-block-title page-title">The Pool Store /
                                                    <span> Pool Perks</span>
                                                </h4>
                                            </div>{/* .nk-block-head-content */}
                                            <div className="d-flex">
                                                {/* <div className="drodown mr-3">
                                                    <a href="#" className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white exportDrop" data-bs-toggle="dropdown">Export </a>
                                                    <div className="dropdown-menu dropdown-menu-end">
                                                        <ul className="link-list-opt no-bdr">
                                                            <li><a href="#"><span>On Hold</span></a></li>
                                                            <li><a href="#"><span>Delivered</span></a></li>
                                                            <li><a href="#"><span>Rejected</span></a></li>
                                                        </ul>
                                                    </div>
                                                </div> */}
                                                <Link to={"/add-perks"} className="btn btn-primary d-md-inline-flex fRight">Add New Perk</Link>
                                            </div>
                                        </div>{/* .nk-block-between */}
                                    </div>{/* .nk-block-head */}


                                    <div className="nk-block">
                                        <div className="row g-gs">
                                            <div className="col-md-6">
                                                <div className="circleChartFlex">
                                                    <div className="">
                                                        <span>Pool Perks Activity</span>
                                                        <h6>Showing all {poolPerkData.count} perks</h6>
                                                    </div>
                                                    <div className="d-flex">
                                                        {/* <a href="" className="thiryday">30 Days</a> */}
                                                        <div className="drodown">
                                                            {/* <a className="dropdown-toggle btn btn-outline-light btn-white filterBnt" data-bs-toggle="dropdown" aria-expanded="false">Filter
                                                                <em className="icon ni ni-filter-alt"></em>
                                                            </a> */}
                                                            <div className="dropdown-menu dropdown-menu-end">
                                                                <ul className="link-list-opt no-bdr">
                                                                    <li>
                                                                        <a onClick={e => { setSortBy('updated_at'); setNextPage("") }}>
                                                                            <input className='me-2' type="radio" checked={sortBy == "updated_at"} onChange={e => { setSortBy('updated_at'); setNextPage("") }} name="" id="" />
                                                                            <label htmlFor="">Last update</label>
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a onClick={e => { setSortBy('total_spent'); setNextPage("") }}>
                                                                            <input className='me-2' type="radio" checked={sortBy == "total_spent"} onChange={e => { setSortBy('total_spent'); setNextPage("") }} name="" id="" />
                                                                            <label htmlFor="">Amount spent</label>
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a onClick={e => { setSortBy('orders_count'); setNextPage("") }}>
                                                                            <input className='me-2' type="radio" checked={sortBy == "orders_count"} onChange={e => { setSortBy('orders_count'); setNextPage("") }} name="" id="" />
                                                                            <label htmlFor="">Total orders</label>
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a onClick={e => { setSortBy('created_at'); setNextPage("") }}>
                                                                            <input className='me-2' type="radio" checked={sortBy == "created_at"} onChange={e => { setSortBy('created_at'); setNextPage("") }} name="" id="" />
                                                                            <label htmlFor="">Date added as customer</label>
                                                                        </a>
                                                                    </li>
                                                                    <hr style={{ margin: "5px 0px" }} />
                                                                    <li>
                                                                        <a onClick={e => { setOrderyBy('asc'); setNextPage("") }} className={orderBy == 'asc' && 'text-primary'}>
                                                                            <em className="icon ni ni-arrow-up"></em>
                                                                            <label htmlFor="">Lowest to highest</label>
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a onClick={e => { setOrderyBy('desc'); setNextPage("") }} className={orderBy == 'desc' && 'text-primary'}>
                                                                            <em className="icon ni ni-arrow-down"></em>
                                                                            <label htmlFor="">Highest to lowest</label>
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="nk-block">
                                                <div className="row g-gs">
                                                    <div className="col-md-6">
                                                        <div className="btn-group justify-content-start mr-2">
                                                            <div className="">
                                                                {/* <h6>Posts activity</h6> */}
                                                                {/* <h6>Showing 10 rows</h6> */}
                                                            </div>

                                                            <div className="">
                                                                <button className="btn btn-outline-light btn-white px-5" onClick={() => setCurrentView(false)} style={{ marginRight: '15px', borderTopLeftRadius: "0px", borderBottomLeftRadius: "0px", background: currentView == false && "#526484", color: currentView == false && "#ffff" }}>Current </button>
                                                                <button className="btn btn-outline-light btn-white px-4" onClick={goToHistoryPage} style={{ borderTopRightRadius: "0px", borderBottomRightRadius: "0px", background: currentView && "#526484", color: currentView && "#fff" }}>History</button>
                                                            </div>


                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-control-wrap searchBarTable">
                                                            <div className="form-icon form-icon-right">
                                                                <em className="icon ni ni-search"></em></div>
                                                            <input type="text" value={searchCustomer} onChange={e => setSearchCustomer(e.target.value)} className="form-control" id="fv-email" name="fv-email" placeholder='Search Here...' />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>



                                        </div>{/* .row */}
                                    </div>



                                    {spinnerLoading
                                        ? <div className='vh-100 flex justify-center items-center'>
                                            <Oval
                                                height="100"
                                                width="100"
                                                color='var(--ps-main)'
                                                secondaryColor="var(--ps-main)"
                                                ariaLabel='oval-loading'
                                                strokeWidth={4}
                                                strokeWidthSecondary={4}
                                                visible={true}
                                            />
                                        </div>
                                        :
                                        <div className="card card-preview " style={{ overflowX: "scroll" }}>
                                            <table className="table table-orders mt-3 ">
                                                <thead className="tb-odr-head dashboardTableHead">
                                                    <tr className="tb-odr-item">
                                                        <th className="tb-odr-info"><span className="tb-odr-id">Image</span></th>

                                                        <th className="tb-odr-info"><span className="tb-odr-id">Barcode</span></th>

                                                        <th className="tb-odr-amount"><span className="tb-odr-total">Title</span></th>
                                                        <th className="tb-odr-amount"><span className="tb-odr-total">Used Count</span></th>
                                                        <th className="tb-odr-amount"><span className="tb-odr-status">Discount Id</span></th>
                                                        <th className="tb-odr-amount"><span className="tb-odr-status">Discount Value</span></th>

                                                        <th className="tb-odr-amount"><span className="tb-odr-status">Discount Code</span></th>

                                                        <th className="tb-odr-amount"><span className="tb-odr-status">Status</span></th>

                                                        <th className="tb-odr-amount"><span className="tb-odr-status">Start Date</span></th>

                                                        <th className="tb-odr-amount"><span className="tb-odr-status">End Date</span></th>

                                                        <th className="tb-odr-amount"><span className="tb-odr-status">Action</span></th>
                                                    </tr>
                                                </thead>
                                                <tbody className="tb-odr-body whatSnewTable">
                                                    {poolPerkData?.rows?.length > 0 &&
                                                        poolPerkData.rows.map(data => {

                                                            return <tr className="tb-odr-item">
                                                                <td className="tb-odr-info">
                                                                    <Image src={data.image_url} style={{ width: "100px" }} alt="" />
                                                                </td>
                                                                <td className="tb-odr-info">
                                                                    <Image src={data.barcode_url} style={{ width: "100px", background: 'white' }} className="ant-image-preview-img" alt="" />
                                                                </td>
                                                                <td className="tb-odr-info">{data?.title}</td>
                                                                <td className="tb-odr-info"><Link to='/customer-info' state={{ perkId: data?.id }}>{data?.discount_user_count}</Link></td>
                                                                {/* <td className="tb-odr-info">{data?.discount_user_count}</td> */}
                                                                <td className="tb-odr-info">{data?.discount_id}</td>
                                                                <td className="tb-odr-info">{data?.discount_value}</td>
                                                                <td className="tb-odr-info">{data?.discount_code}</td>
                                                                <td className="tb-odr-info">{data?.discount_status}</td>
                                                                <td className="tb-odr-info">{moment(data?.start_date).format("YYYY-MM-DD")}</td>
                                                                <td className="tb-odr-info">{moment(data?.end_date).format("YYYY-MM-DD")}</td>
                                                                <td className="tb-odr-info">
                                                                    {/* <a className="text-soft btn btn-icon">
                                                                        <em className="icon ni ni-eye"></em>
                                                                    </a> */}
                                                                    {/* <Link to={`/whats-new/${data.id}`} role='button' className="text-soft btn btn-icon"> */}
                                                                    <Link to={`/edit-perks/${data.id}`} role='button' className="text-soft btn btn-icon">
                                                                        <em className="icon ni ni-edit"></em>
                                                                    </Link>
                                                                    <a onClick={e => handleDeletePerk(e, data.id)} className="text-soft btn btn-icon">
                                                                        <em className="icon ni ni-trash-empty"></em>
                                                                    </a>
                                                                </td>
                                                            </tr>

                                                        })}
                                                </tbody>
                                            </table>

                                            {poolPerkData?.rows?.length == 0 &&
                                                <div className='d-flex justify-content-center align-item-center mt-5'>
                                                    <span>No Data Available</span>
                                                </div>
                                            }

                                        </div> 
                                    }

                                    {/* <div className='d-flex justify-content-between mt-3'>
                                        <button
                                            disabled={customerData.previousPageParameters == null}
                                            className="btn btn-primary d-md-inline-flex fRight"
                                            onClick={e => { setSortBy(""); setOrderyBy(""); setNextPage(customerData.previousPageParameters.page_info) }}
                                        >
                                            Previous Page
                                        </button>
                                        <button
                                            disabled={customerData.nextPageParameters == null}
                                            className="btn btn-primary d-md-inline-flex fRight"
                                            onClick={e => { setSortBy(""); setOrderyBy(""); setNextPage(customerData.nextPageParameters.page_info) }}
                                        >
                                            Next Page
                                        </button>
                                    </div> */}


                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mt-2">
                            <ReactPaginate
                                previousLabel={"Previous"}
                                nextLabel={'Next'}
                                forcePage={pageNumber - 1}
                                breakLabel={"..."}
                                // pageCount={10}
                                pageCount={activePageCount}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={2}
                                onPageChange={handlePageChange}
                                containerClassName={'pagination justify-content-center'}
                                pageClassName={'page-item'}
                                pageLinkClassName={'page-link'}
                                previousClassName={'page-item'}
                                previousLinkClassName={'page-link'}
                                nextClassName={'page-item'}
                                nextLinkClassName={'page-link'}
                                breakClassName={"page-item"}
                                breakLinkClassName={'page-link'}
                                activeClassName={'active'}
                            />
                        </div>
                    </div>

                </Container>
            }
        </>
    );
}

export default PoolPerks;
