import React, { useState } from 'react';
import { Oval } from 'react-loader-spinner'
import toast, { Toaster } from 'react-hot-toast';
import { useFormik } from 'formik';
import * as yup from 'yup'
import SideScreen from './SideScreen';
import { deleteAccountRequest } from '../../API/WebRequest';

const DeleteAccount = () => {

    const [spinnerLoading, setSpinnerLoading] = useState(false)

    const accountDeleteForm = useFormik({
        initialValues: {
            email: "",
            message: ""
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            email: yup.string().email('Please enter a valid email address').required('Email is required'),
            message: yup.string().required('Message is required for account deletion'),
        }),
        onSubmit: async (values) => {
            setSpinnerLoading(true)
            await deleteAccountRequest(values).then((res) => {
                toast.success(res.data.message)
            }).catch((err) => {
                console.log("🚀 ~ awaitdeleteAccountRequest ~ err:", err)

                if (err.code == "ERR_NETWORK") {
                    toast.error("Something went wront! please try again later")
                }
                else {
                    toast.error(err.response.data.message)
                }
            })
            setSpinnerLoading(false)

        }
    })

    return (
        <>
            <Toaster position="top-right" reverseOrder={false}
                toastOptions={{
                    // Define default options
                    className: '',
                    duration: 5000,
                    style: {
                        background: '#363636',
                        color: '#fff',
                    },

                    // Default options for specific types
                    success: {
                        duration: 5000,
                        theme: {
                            primary: 'green',
                            secondary: 'black',
                        },
                    },
                }} />
            <div className="nk-body bg-white npc-default pg-auth">
                <div className="nk-app-root">
                    {/* <div className="loginSection"> */}
                    <div className="">
                        <div className="container">
                            <div className="row align-items-center justify-content-center">
                                <div className="col-md-6">
                                    <div className="card">
                                        <div className="card-inner card-inner-lg">
                                            <div className="nk-block-head">
                                                <div className="nk-block-head-content">
                                                    <h4 className="nk-block-title">Delete Account</h4>
                                                </div>
                                            </div>
                                            <form onSubmit={accountDeleteForm.handleSubmit}>
                                                <div className="form-group">
                                                    <div className="form-label-group">
                                                        <label className="form-label" htmlFor="default-01">Email Address</label>
                                                    </div>
                                                    <div className="form-control-wrap">
                                                        <input
                                                            type="email"
                                                            name='email'
                                                            {...accountDeleteForm.getFieldProps("email")}
                                                            className="form-control form-control-lg"
                                                            id="default-01"
                                                            placeholder="Enter your email address"
                                                        />
                                                        {accountDeleteForm.touched.email && accountDeleteForm.errors.email ?
                                                            <div className="invalid-feedback" style={{ display: "block" }}>{accountDeleteForm.errors.email}</div> : ''}
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <div className="form-label-group">
                                                        <label className="form-label" htmlFor="message">Message</label>
                                                    </div>
                                                    <div className="form-control-wrap">
                                                        <textarea
                                                            name="message"
                                                            {...accountDeleteForm.getFieldProps("message")}
                                                            className="form-control form-control-lg"
                                                            id="message"
                                                            placeholder="Type your message here"
                                                        />
                                                        {accountDeleteForm.touched.message && accountDeleteForm.errors.message ?
                                                            <div className="invalid-feedback" style={{ display: "block" }}>{accountDeleteForm.errors.message}</div> : ''}
                                                    </div>

                                                </div>
                                                <div className="form-group justify-center mt-3">
                                                    {spinnerLoading
                                                        ? <Oval
                                                            height="40"
                                                            width="40"
                                                            color='var(--ps-main)'
                                                            secondaryColor="var(--ps-main)"
                                                            ariaLabel='oval-loading'
                                                            strokeWidth={4}
                                                            strokeWidthSecondary={4}
                                                            visible={spinnerLoading}
                                                        />
                                                        : <button type="submit" className="btn btn-lg btn-primary btn-block">Submit</button>
                                                    }
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-md-6">
                                    <SideScreen />
                                </div>




                            </div>
                        </div>

                    </div>
                    {/* main @e */}
                </div>
            </div>

        </>
    );
}

export default DeleteAccount;
