import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import Dashboard from './Components/Dashboard/Dashboard';
import Login from './Components/Authentication/Login';
import ForgetPassword from './Components/Authentication/ForgetPassword';
import ChangePassword from './Components/Authentication/ChangePassword';
import ResetSuccess from './Components/Authentication/ResetSuccess';
import Profile from './Components/AdminProfile/Profile';
import WhatsNew from './Components/WhatsNew/WhatsNew';
import AddNewPost from './Components/WhatsNew/AddNewPost';
import WelcomeScreen from './Components/WelcomeScreen/WelcomeScreen';
import Products from './Components/ManageProducts/Products';
import ManageOrders from './Components/ManageOrders/ManageOrders';
import CustomerDatabase from './Components/Customer/CustomerDatabase';
import CustomerDetail from './Components/Customer/CustomerDetail';
import ChatScreen from './Components/Chat/ChatScreen';
import AddWelcomeScreen from './Components/WelcomeScreen/AddWelcomeScreen';
import EditWelcomeScreen from './Components/WelcomeScreen/EditWelcomeScreen';
import EditWhatsNew from './Components/WhatsNew/EditWhatsNew';
import ViewWhatsNew from './Components/WhatsNew/ViewWhatsNew';

import ComingSoonPage from './Components/Pages/ComingSoonPage';
import Discount from './Components/Discounts/Discount';
import AddDiscount from './Components/Discounts/AddDiscount';
import EditDiscountBanner from './Components/Discounts/EditDiscountBanner';
import PoolPerks from './Components/PoolPerks/PoolPerks';
import AddPerks from './Components/PoolPerks/AddPerks';
import CustomerInfo from './Components/PoolPerks/CustomerInfo';
import EditPerks from './Components/PoolPerks/EditPerks';
import DeleteAccount from './Components/DeleteAccountWeb/DeleteAccount';
import PoolPerksHistory from './Components/PoolPerks/PoolPerksHistory';
import CustomerHistoryInfo from './Components/PoolPerks/CustomerHistoryInfo';




function App() {

  const user = useSelector((state) => state.authReducer.authData)

  return (
    <>
      <Router>
        <div>
          <Routes>
            <Route path='/' element={<Dashboard />} />

            {/* Auth Routes */}
            <Route path='/login' element={<Login />} />
            <Route path='/forget-password' element={<ForgetPassword />} />
            <Route path='/change-password' element={<ChangePassword />} />
            <Route path='/reset-success' element={<ResetSuccess />} />

            {/* What's new Routes */}
            <Route path='/whats-new' element={<WhatsNew />} />
            <Route path='/add-new-post' element={<AddNewPost />} />
            <Route path='/whats-new/:id' element={<EditWhatsNew />} />
            <Route path='/whats-new-view/:id' element={<ViewWhatsNew />} />

            {/* Onboarding Routes */}
            <Route path='/welcome-screen' element={<WelcomeScreen />} />
            <Route path='/add-welcome-screen' element={<AddWelcomeScreen />} />
            <Route path='/welcome-screen/:id' element={<EditWelcomeScreen />} />


            {/* Discount Routes */}
            <Route path='/discount' element={<Discount />} />
            <Route path='/add-discount' element={<AddDiscount />} />
            <Route path='/edit-discount/:id' element={<EditDiscountBanner />} />

            {/* Customer Routes */}
            <Route path='/customer-database' element={<CustomerDatabase />} />
            <Route path='/customer-detail/:id' element={<CustomerDetail />} />
            <Route path='/add-customer' element={<ComingSoonPage pageName={'Add Customer'} />} />

            {/* Manage Product */}
            <Route path='/manage-products' element={<Products />} />
            <Route path='/add-products' element={<ComingSoonPage pageName={'Add Product'} />} />

            {/* Manage Orders */}
            <Route path='/manage-orders' element={<ComingSoonPage pageName={'Manage Order'} />} />

            {/* Chat */}
            <Route path='/chat' element={<ChatScreen />} />

            {/* Pool Perks */}
            <Route path='/pool-perks' element={<PoolPerks />} />
            <Route path='/add-perks' element={<AddPerks />} />
            <Route path='/edit-perks/:id' element={<EditPerks />} />
            <Route path='/customer-info' element={<CustomerInfo />} />
            <Route path='/customer-history-info' element={<CustomerHistoryInfo />} />
            <Route path='/pool-perks-history' element={<PoolPerksHistory />} />


            {/* User Routes */}
            <Route path='/delete-account-request' element={<DeleteAccount />} />

            {/* User Routes */}
            <Route path='/profile' element={<Profile />} />
          </Routes>
        </div>
      </Router>
    </>
  );
}

export default App;
