import axios from "axios";

const API = axios.create({ baseURL: process.env.REACT_APP_BASE_URL })
// const API = axios.create({ baseURL: "http://192.168.29.181" }) // Incorrect URL

export const discountBannerApi = (headers) => API.get(`/admin/discount-banners`, headers)
export const singleDiscountBannerApi = (headers, id) => API.get(`/admin/discount-banner/${id}`, headers)
export const addDiscountBannerApi = (formData, headers) => API.post(`/admin/discount-banner/add`, formData, headers)
export const editDiscountBannerApi = (formData, headers, id) => API.post(`/admin/discount-banner/update/${id}`, formData, headers)
export const deleteDiscountBannerApi = (headers, id) => API.delete(`/admin/discount-banner/delete/${id}`, headers)
export const poolPerksAllApi = (headers) => API.get(`/admin/get-pool-perks/banners`, headers)
