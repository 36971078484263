import React, { useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { Oval } from 'react-loader-spinner'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Container from '../Layouts/Container';
// import { allCustomerListAll } from '../../API/customerRequest';
import { userCustomerHistoryInfoAPI } from '../../API/PoolPerkRequest'
import { sessionExpire } from '../../Scripts/Helper';
import { useState } from 'react';
import { logoutAdmin } from '../../Action/authAction';
import ReactPaginate from 'react-paginate';

const CustomerHistoryInfo = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const perkId = useLocation().state.perkId

    console.log("perkId", perkId)

    const user = useSelector((state) => state.authReducer.authData)
    const authToken = useSelector((state) => state.authReducer.token)

    const [spinnerLoading, setSpinnerLoading] = useState(false)
    const [customerData, setCustomerData] = useState([])
    const [nextPage, setNextPage] = useState("")
    const [searchCustomer, setSearchCustomer] = useState("")
    const [sortBy, setSortBy] = useState("")
    const [orderBy, setOrderyBy] = useState("")

    const [totalCustomer, setTotalCustomer] = useState([])

    const [queryString, setqueryString] = useState("")
    const [endCursor, setendCursor] = useState("")
    const [startCursor, setstartCursor] = useState("")
    const [hasNextPage, sethasNextPage] = useState(false)
    const [hasPreviousPage, sethasPreviousPage] = useState(false)

    const [activePostData, setActivePostData] = useState({})
    const [activePageCount, setActivePageCount] = useState(0)
    const [pageNumber, setPagenumber] = useState(1)

    let fetchCustomerList = async (endCursor_ = '', startCursor_ = '') => {
        setSpinnerLoading(true)
        const config = {
            headers: { Authorization: `Bearer ${authToken}` }
        }
        await userCustomerHistoryInfoAPI(perkId, config, pageNumber)
            .then((response) => {

                console.log(response.data)
                // setendCursor(response.data.data.endCursor)
                // setstartCursor(response.data.data.startCursor)
                // sethasPreviousPage(response.data.data.hasPreviousPage)
                // sethasNextPage(response.data.data.hasNextPage)
                setCustomerData(response ? response.data ? response.data.data : [] : [])
                setTotalCustomer(response.data.count)

                setActivePostData({
                    count: response.data.count,
                    currentPage: response.data.currentPage,
                    totalPages: response.data.totalPages,
                })
                setActivePageCount(Math.ceil(response.data.count / 10))

                console.log(totalCustomer, 'totalCustomer')

            }).catch((err) => {
                console.log("🚀 ~ file: CustomerDatabase.jsx:31 ~ .then ~ err:", err)

                if (err.code == "ERR_NETWORK") {
                    toast.error("Something went wron.t! please try again later")
                }
                else {
                    let res = sessionExpire(err)
                    console.log("🚀 ~ file: CustomerDatabase.jsx:44 ~ .then ~ res:", res)
                    toast.error(res.message)

                    if (res.status) {
                        dispatch(logoutAdmin())
                        navigate('/login')
                    }
                }
            })
        setSpinnerLoading(false)
    }
    useEffect(() => {
        setendCursor("")
        setstartCursor("")
        sethasPreviousPage(false)
        sethasNextPage(false)
        setCustomerData([])
        fetchCustomerList()
    }, [])


    useEffect(() => {

        //     if(nextPage == 0) {
        //         // setendCursor("")
        //         fetchCustomerList('',startCursor);
        //     } else{
        //         // setstartCursor("")
        //         fetchCustomerList(endCursor,'');
        //     }
        fetchCustomerList();
    }, [searchCustomer,pageNumber])

    const handlePageChange = async (d) => {
        window.scrollTo(0, 0);
        var CurrentPage = d.selected + 1;
        setPagenumber(CurrentPage)

    }


    const getNewRecords = (page) => {
        if (page == 0) {
            // setendCursor("")
            fetchCustomerList('', '');
        } else {
            // setstartCursor("")
            fetchCustomerList(endCursor, '');
        }
    }

    // useEffect(() => {
    //     !user && navigate('/login')
    //     fetchCustomerList()
    // }, [queryString, endCursor, startCursor])

    return (
        <>
            <Toaster position="top-right" reverseOrder={false} />
            {user &&
                <Container>
                    <div className="nk-content mt-5 ">
                        <div className="container-fluid">
                            <div className="nk-content-inner">
                                <div className="nk-content-body">

                                    <div className="nk-block-head nk-block-head-sm mt-3">
                                        <div className="nk-block-between">
                                            <div className="nk-block-head-content">
                                                <h4 className="nk-block-title page-title">The Pool Store /
                                                    <span> Discount Customer Info</span>
                                                </h4>
                                            </div>{/* .nk-block-head-content */}
                                            <div className="d-flex">
                                                {/* <div className="drodown mr-3">
                                                    <a href="#" className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white exportDrop" data-bs-toggle="dropdown">Export </a>
                                                    <div className="dropdown-menu dropdown-menu-end">
                                                        <ul className="link-list-opt no-bdr">
                                                            <li><a href="#"><span>On Hold</span></a></li>
                                                            <li><a href="#"><span>Delivered</span></a></li>
                                                            <li><a href="#"><span>Rejected</span></a></li>
                                                        </ul>
                                                    </div>
                                                </div> */}
                                                {/* <Link to={"/add-customer"} className="btn btn-primary d-md-inline-flex fRight">Add Customer</Link> */}
                                            </div>
                                        </div>{/* .nk-block-between */}
                                    </div>{/* .nk-block-head */}


                                    <div className="nk-block">
                                        <div className="row g-gs">
                                            <div className="col-md-6">
                                                <div className="circleChartFlex">
                                                    <div className="">
                                                        <h6>Total Number of Customers  ({totalCustomer}) </h6>
                                                    </div>
                                                    <div className="d-flex">
                                                        {/* <a href="" className="thiryday">30 Days</a> */}
                                                        <div className="drodown">
                                                            {/* <a className="dropdown-toggle btn btn-outline-light btn-white filterBnt" data-bs-toggle="dropdown" aria-expanded="false">Filter
                                                                <em className="icon ni ni-filter-alt"></em>
                                                            </a> */}
                                                            <div className="dropdown-menu dropdown-menu-end">
                                                                <ul className="link-list-opt no-bdr">
                                                                    <li>
                                                                        <a onClick={e => { setSortBy('updated_at'); setNextPage("") }}>
                                                                            <input className='me-2' type="radio" checked={sortBy == "updated_at"} onChange={e => { setSortBy('updated_at'); setNextPage("") }} name="" id="" />
                                                                            <label htmlFor="">Last update</label>
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a onClick={e => { setSortBy('total_spent'); setNextPage("") }}>
                                                                            <input className='me-2' type="radio" checked={sortBy == "total_spent"} onChange={e => { setSortBy('total_spent'); setNextPage("") }} name="" id="" />
                                                                            <label htmlFor="">Amount spent</label>
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a onClick={e => { setSortBy('orders_count'); setNextPage("") }}>
                                                                            <input className='me-2' type="radio" checked={sortBy == "orders_count"} onChange={e => { setSortBy('orders_count'); setNextPage("") }} name="" id="" />
                                                                            <label htmlFor="">Total orders</label>
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a onClick={e => { setSortBy('created_at'); setNextPage("") }}>
                                                                            <input className='me-2' type="radio" checked={sortBy == "created_at"} onChange={e => { setSortBy('created_at'); setNextPage("") }} name="" id="" />
                                                                            <label htmlFor="">Date added as customer</label>
                                                                        </a>
                                                                    </li>
                                                                    <hr style={{ margin: "5px 0px" }} />
                                                                    <li>
                                                                        <a onClick={e => { setOrderyBy('asc'); setNextPage("") }} className={orderBy == 'asc' && 'text-primary'}>
                                                                            <em className="icon ni ni-arrow-up"></em>
                                                                            <label htmlFor="">Lowest to highest</label>
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a onClick={e => { setOrderyBy('desc'); setNextPage("") }} className={orderBy == 'desc' && 'text-primary'}>
                                                                            <em className="icon ni ni-arrow-down"></em>
                                                                            <label htmlFor="">Highest to lowest</label>
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="col-md-6">
                                                <div className="form-control-wrap searchBarTable">
                                                    <div className="form-icon form-icon-right">
                                                        <em className="icon ni ni-search"></em></div>
                                                    <input type="text" value={searchCustomer} onChange={e => setSearchCustomer(e.target.value)} className="form-control" id="fv-email" name="fv-email" placeholder='Search Here...' />
                                                </div>
                                            </div> */}

                                        </div>{/* .row */}
                                    </div>



                                    {spinnerLoading
                                        ? <div className='vh-100 flex justify-center items-center'>
                                            <Oval
                                                height="100"
                                                width="100"
                                                color='var(--ps-main)'
                                                secondaryColor="var(--ps-main)"
                                                ariaLabel='oval-loading'
                                                strokeWidth={4}
                                                strokeWidthSecondary={4}
                                                visible={true}
                                            />
                                        </div>
                                        :
                                        <div className="card card-preview">
                                            <table className="table table-orders mt-3">
                                                <thead className="tb-odr-head dashboardTableHead">
                                                    <tr className="tb-odr-item">
                                                        <th className="tb-odr-info"><span className="tb-odr-id">Customer Name</span></th>
                                                        <th className="tb-odr-amount"><span className="tb-odr-total">E-mail </span></th>

                                                        <th className="tb-odr-amount"><span className="tb-odr-status">Phone</span></th>

                                                        {/* <th className="tb-odr-amount"><span className="tb-odr-status">Orders</span></th> */}

                                                        {/* <th className="tb-odr-amount"><span className="tb-odr-status">Amount Spent</span></th> */}
                                                    </tr>
                                                </thead>
                                                <tbody className="tb-odr-body whatSnewTable">
                                                    {customerData?.length > 0 &&
                                                        customerData.map(data => {

                                                            return <tr className="tb-odr-item">
                                                                <td className="tb-odr-info">{data.first_name + ' ' + data.last_name}</td>

                                                                <td className="tb-odr-info"><Link to={`/customer-detail/${data.shopify_customer_id}`}>
                                                                    {data.email && <p>{data.email}</p>}
                                                                </Link></td>
                                                                {/* <td className="tb-odr-info">
                                                                    <span className={data?.emailMarketingConsent?.marketingState == 'SUBSCRIBED' ? "badge rounded-pill badge-dim bg-outline-success" : "badge rounded-pill badge-dim bg-secondary"}>{data ? data?.emailMarketingConsent?.marketingState == "SUBSCRIBED" ? "Subscribed" : "Not Subscribed" : ""}</span>
                                                                </td> */}
                                                                {/* <td className="tb-odr-info">{data?.defaultAddress?.address1}  {data?.defaultAddress?.city} {data?.defaultAddress?.country}</td> */}
                                                                <td className="tb-odr-info">{data.phone}</td>
                                                                {/* <td className="tb-odr-info">${data.amountSpent?.amount} {data.amountSpent?.currencyCode}</td> */}
                                                            </tr>

                                                        })}
                                                </tbody>
                                            </table>

                                            {customerData?.customers?.length == 0 &&
                                                <div className='d-flex justify-content-center align-item-center mt-5'>
                                                    <span>No Data Available</span>
                                                </div>
                                            }

                                        </div>
                                    }

                                    <div className='d-flex justify-content-between mt-3'>
                                        {/* <button
                                            disabled={hasPreviousPage == false}
                                            className="btn btn-primary d-md-inline-flex fRight"
                                            onClick={e => { setSortBy(""); setOrderyBy(""); getNewRecords(0) }}
                                        >
                                            Previous Page
                                        </button> */}
                                        {/* <button
                                            disabled={hasNextPage == false}
                                            className="btn btn-primary d-md-inline-flex fRight"
                                            onClick={e => { setSortBy(""); setOrderyBy(""); getNewRecords(1) }}
                                        >
                                            Next Page
                                        </button> */}
                                    </div>


                                </div>
                            </div>
                        </div>


                        <div className="d-flex justify-content-between mt-2">
                            <ReactPaginate
                                previousLabel={"Previous"}
                                nextLabel={'Next'}
                                forcePage={pageNumber - 1}
                                breakLabel={"..."}
                                // pageCount={10}
                                pageCount={activePageCount}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={2}
                                onPageChange={handlePageChange}
                                containerClassName={'pagination justify-content-center'}
                                pageClassName={'page-item'}
                                pageLinkClassName={'page-link'}
                                previousClassName={'page-item'}
                                previousLinkClassName={'page-link'}
                                nextClassName={'page-item'}
                                nextLinkClassName={'page-link'}
                                breakClassName={"page-item"}
                                breakLinkClassName={'page-link'}
                                activeClassName={'active'}
                            />
                        </div>

                    </div>

                </Container>
            }
        </>
    );
}

export default CustomerHistoryInfo;
