import axios from "axios";

const API = axios.create({ baseURL: process.env.REACT_APP_BASE_URL })
// const API = axios.create({ baseURL: "http://192.168.29.181" }) // Incorrect URL

export const userChatListAPI = (headers, search) => API.get(`/admin/get-all-chats?search=${search}`, headers)
export const userChatHistoryAPI = (headers, customerId, chat_service_id, chat_service_equipment_id) => API.get(`/admin/get-all-chats/${customerId}/${chat_service_id}/${chat_service_equipment_id}`, headers)
// export const sendChatMsgAPI = (headers, formData, customerId) => API.post(`/admin/send-message/${customerId}`, formData, headers)
export const uploadImageAPI = (headers, formData, customerId) => API.post(`/admin/upload-image/${customerId}`, formData, headers)

export const chatServiceListAPI = (headers, cusId) => API.get(`/admin/get-all-chat-service/${cusId}`, headers)

export const closeChatRequestAPI = (headers, chat_request_id) => API.get(`/admin/request-close/${chat_request_id}`, headers)

export const deleteChatRequestAPI = (headers, chat_request_id) => API.delete(`/admin/request-delete/${chat_request_id}`, headers)

// export const onboardingScreenAPI = (headers) => API.get(`/admin/onboarding-screens`, headers)
// export const singleOnboardingScreenAPI = (screen_id, headers) => API.get(`/admin/onboarding-screen/${screen_id}`, headers)
// export const updateOnboardingScreenAPI = (screen_id, formData, headers) => API.post(`/admin/onboarding-screen/update/${screen_id}`, formData, headers)
// export const deleteOnboardingScreenAPI = (screen_id, headers) => API.delete(`/admin/onboarding-screen/delete/${screen_id}`, headers)