import React, { useEffect, useRef, useState } from 'react';
import { Oval } from 'react-loader-spinner'
import toast, { Toaster } from 'react-hot-toast';
import { useFormik } from 'formik';
import * as yup from 'yup'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate,useParams } from 'react-router-dom';
import Container from '../Layouts/Container';
import moment from 'moment-timezone';
import { useDropzone } from 'react-dropzone';
import { sessionExpire } from '../../Scripts/Helper';
import { logoutAdmin } from '../../Action/authAction';
import { addNewPerkAPI,editewPerkAPI,singlePerkAPI } from '../../API/PoolPerkRequest';

const EditPerks = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const imageRef = useRef(null)
    const perk_id = useParams().id
    const user = useSelector((state) => state.authReducer.authData)
    const authToken = useSelector((state) => state.authReducer.token)

    // Get the user's time zone
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const [spinnerLoading, setSpinnerLoading] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false)
    const [amountType, setAmountType] = useState("Fixed_Amount")
    const [discountType, setDiscountType] = useState("MANUAL")
    const [discountStatus, setDiscountStatus] = useState("ACTIVE")
    const [startDate, setStartDate] = useState()
    const [expiryDate, setExpiryDate] = useState()
    const [perkData, setPerkData] = useState({})
    // const [startDate, setStartDate] = useState(moment.utc(moment()).tz(userTimeZone).format('YYYY-MM-DD'))
    // const [expiryDate, setExpiryDate] = useState(moment.utc(moment().add(1, 'day')).tz(userTimeZone).format('YYYY-MM-DD'))
    const [screenImage, setScreenImage] = useState("")

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: (acceptedFiles) => {
            setScreenImage(acceptedFiles[0])
            editPerkForm.setFieldValue("image", URL.createObjectURL(acceptedFiles[0]))
        },
    });

    const editPerkForm = useFormik({
        initialValues: {
            title: perkData.title,
            discount_value: perkData.discount_value,
            discount_value_type: perkData.discount_value_type,
            discount_code:perkData.discount_code,
            discount_type:perkData.discount_type,
            discount_status:perkData.discount_status,
            image: perkData.image_url,
            start_date: perkData.start_date,
            end_date:moment.utc(perkData.end_date).tz(userTimeZone).format('YYYY-MM-DD')
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            title: yup.string().required('Title is required'),
            discount_value: yup.string().required('Discount amount is required'),
            discount_code: yup.string().required('Discount code is required'),
            image: yup.string().required('Image is required'),
            start_date: yup
            .string()
            .required('Start Date is required')
            .test('start-date', 'Start date must be less than end date', function (value) {
            const { end_date } = this.parent;
            return moment.utc(value).isSameOrBefore(moment.utc(end_date));
            }),
            end_date: yup
            .string()
            .required('Expiration Date is required')
            .test('end-date', 'End date must be greater than start date', function (value) {
            const { start_date } = this.parent;
            return moment.utc(value).isSameOrAfter(moment.utc(start_date));
            }),
            // start_date: yup.string().required('Start Date is required'),
            // end_date: yup.string().required('Expiration Date is required'),
        }),
        onSubmit: async (values, { resetForm }) => {
            setBtnLoading(true)

            const formData = new FormData();
            formData.append("title", editPerkForm.getFieldProps('title').value)
            formData.append("discount_value", editPerkForm.getFieldProps('discount_value').value)
            formData.append("discount_value_type", amountType)
            formData.append("discount_code", editPerkForm.getFieldProps('discount_code').value)
            formData.append("discount_type", discountType)
            formData.append("discount_status", discountStatus)
            formData.append("image", screenImage);
            formData.append("start_date", moment(startDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"));
            formData.append("end_date", moment(expiryDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"));

            const config = {
                headers: { Authorization: `Bearer ${authToken}` }
            }
            await editewPerkAPI(perk_id,config, formData)
                .then((res) => {

                    navigate('/pool-perks')
                    setTimeout(() => {
                        toast.success(res.data.message)
                    }, 100);

                }).catch((err) => {
                    console.log("🚀 ~ file: Profile.jsx:80 ~ err:", err)

                    if (err.code == "ERR_NETWORK") {
                        toast.error("Something went wront! please try again later")
                    }
                    else {
                        let res = sessionExpire(err)
                        toast.error(res.message)

                        if (res.status) {
                            dispatch(logoutAdmin())
                            navigate('/login')
                        }
                    }
                })

            setBtnLoading(false)
        }
    })

    useEffect(() => {
        !user && navigate('/login')
        fetchPoolPerkData()
    }, []);

    let fetchPoolPerkData = async () => {
        setSpinnerLoading(true)
        const config = {
            headers: { Authorization: `Bearer ${authToken}` }
        }
        await singlePerkAPI(perk_id, config)
            .then((response) => {
                setPerkData(response ? response.data ? response.data.data : {} : {})

                setAmountType(response.data.data.discount_value_type)
                setDiscountType(response.data.data.discount_type)
                setDiscountStatus(response.data.data.discount_status)
                setStartDate(moment.utc(response.data.data.start_date).tz(userTimeZone).format('YYYY-MM-DD'))
                setExpiryDate(moment.utc(response.data.data.end_date).tz(userTimeZone).format('YYYY-MM-DD'))
                setScreenImage(response.data.data.image_url)
                // setPostImage(response.data.data.image_icon)
                // setCurrentDateTime(moment.utc(response.data.data.start_date_time).tz(userTimeZone).format("yyyy-MM-DDTHH:mm"))
                // if (response.data.data.post_exp_time) {
                //     setPostExpiry(true)
                //     setExpDateTime(moment.utc(response.data.data.post_exp_time).tz(userTimeZone).format("yyyy-MM-DDTHH:mm"))
                // }
                // if (response.data.data.is_default) {
                //     setIsPostDefault(true)
                // }
            }).catch((err) => {
                console.log("🚀 ~ file: Profile.jsx:80 ~ err:", err)
            })
        setSpinnerLoading(false)
    }

  
    return (
        <>
            {user &&
                <Container>
                    <Toaster position="top-right" reverseOrder={false} />
                    <div className="nk-content mt-5">
                        <div className="container-fluid mt-4">
                            <div className="nk-content-inner">
                                <div className="nk-content-body">
                                    <div className="nk-block-head nk-block-head-sm">
                                        <div className="nk-block-between">
                                            <div className="nk-block-head-content">
                                                <h4 className="nk-block-title page-title">The Pool Store /
                                                    <span> Edit Pool Perk</span>
                                                </h4>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row g-gs mt-1">
                                        <div className="col-md-12">

                                            <div className="row g-gs">
                                                <form className="row" onSubmit={editPerkForm.handleSubmit}>


                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <div className="form-control-wrap whatNewInput">
                                                                <label className="form-label">Title</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control form-control-lg"
                                                                    placeholder="Title"
                                                                    {...editPerkForm.getFieldProps("title")}
                                                                />
                                                                {editPerkForm.touched.title && editPerkForm.errors.title
                                                                    ? <div className="invalid-feedback" style={{ display: "block" }}>
                                                                        {editPerkForm.errors.title}
                                                                    </div>
                                                                    : ''
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="row mb-2">
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <div className="form-control-wrap whatNewInput">
                                                                        <label className="form-label">Discount Amount</label>
                                                                        <input
                                                                            type="number"
                                                                            className="form-control form-control-lg"
                                                                            placeholder="Enter Discount Amount"
                                                                            {...editPerkForm.getFieldProps("discount_value")}
                                                                        />
                                                                        {editPerkForm.touched.discount_value && editPerkForm.errors.discount_value
                                                                            ? <div className="invalid-feedback" style={{ display: "block" }}>
                                                                                {editPerkForm.errors.discount_value}
                                                                            </div>
                                                                            : ''
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <div className="form-control-wrap whatNewInput">
                                                                        <label className="form-label">Amount Type</label>
                                                                        {/* <input
                                                                            type="text"
                                                                            className="form-control form-control-lg"
                                                                            placeholder="Sub title"
                                                                        /> */}
                                                                        <select className='form-control form-control-lg'  value ={amountType} onChange={e => setAmountType(e.target.value)}>
                                                                            <option value="Fixed_Amount">FIXED AMOUNT</option>
                                                                            <option value="Percentage">PERCENTAGE</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row mb-1">
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <div className="form-control-wrap whatNewInput">
                                                                        <label className="form-label">Discount Code</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control form-control-lg"
                                                                            placeholder="Enter Discount Code"
                                                                            {...editPerkForm.getFieldProps("discount_code")}
                                                                        />
                                                                        {editPerkForm.touched.discount_code && editPerkForm.errors.discount_code
                                                                            ? <div className="invalid-feedback" style={{ display: "block" }}>
                                                                                {editPerkForm.errors.discount_code}
                                                                            </div>
                                                                            : ''
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <div className="form-control-wrap whatNewInput">
                                                                        <label className="form-label">Discount Type </label>
                                                                        {/* <input
                                                                            type="text"
                                                                            className="form-control form-control-lg"
                                                                            placeholder="Sub title"
                                                                            {...editPerkForm.getFieldProps("description")}
                                                                        /> */}
                                                                        <select className='form-control form-control-lg'   value ={discountType} onChange={e => setDiscountType(e.target.value)}>
                                                                            <option value="MANUAL" >MANUAL</option>
                                                                            <option value="AUTOMATIC">AUTOMATIC</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="form-group">
                                                            <div className="form-control-wrap whatNewInput">
                                                                <label className="form-label">Discount Status</label>
                                                                {/* <input
                                                                    type="text"
                                                                    className="form-control form-control-lg"
                                                                    placeholder="Sub title"
                                                                    {...editPerkForm.getFieldProps("description")}
                                                                /> */}
                                                                <select className='form-control form-control-lg' value ={discountStatus} onChange={e => setDiscountStatus(e.target.value)}>
                                                                    <option value="ACTIVE">ACTIVE</option>
                                                                    <option value="INACTIVE">INACTIVE</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                    </div>


                                                    <div className="col-md-6">

                                                        <div className="row mb-1">
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <div className="form-control-wrap whatNewInput">
                                                                        <label className="form-label">Start Date</label>
                                                                        <input
                                                                            type="date"
                                                                            className="form-control form-control-lg"
                                                                            min={moment.utc(moment()).tz(userTimeZone).format('YYYY-MM-DD')}
                                                                            value={startDate}
                                                                            onChange={e => {
                                                                                setStartDate(moment.utc(moment(e.target.value)).tz(userTimeZone).format('YYYY-MM-DD'))
                                                                                editPerkForm.setFieldValue("start_date", e.target.value)
                                                                                setExpiryDate("")
                                                                            }}
                                                                        />
                                                                        {editPerkForm.touched.start_date && editPerkForm.errors.start_date
                                                                            ? <div className="invalid-feedback" style={{ display: "block" }}>
                                                                                {editPerkForm.errors.start_date}
                                                                            </div>
                                                                            : ''
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <div className="form-control-wrap whatNewInput">
                                                                        <label className="form-label">Expiration Date</label>
                                                                        <input
                                                                            type="date"
                                                                            min={moment.utc(moment().add(1, 'day')).tz(userTimeZone).format('YYYY-MM-DD')}
                                                                            value={editPerkForm.values.end_date}
                                                                            className="form-control form-control-lg"
                                                                            onChange={e => {
                                                                                setExpiryDate(moment.utc(moment(e.target.value)).tz(userTimeZone).format('YYYY-MM-DD'))
                                                                                editPerkForm.setFieldValue("end_date", e.target.value)
                                                                            }}
                                                                        />
                                                                        {editPerkForm.touched.end_date && editPerkForm.errors.end_date
                                                                            ? <div className="invalid-feedback" style={{ display: "block" }}>
                                                                                {editPerkForm.errors.end_date}
                                                                            </div>
                                                                            : ''
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="form-group">
                                                            <label className="form-label">Discount Banner</label>
                                                            {screenImage
                                                                ?
                                                                <div>
                                                                    <img src={editPerkForm.values.image} alt="Screen Image" style={{ width: "150px" }} />
                                                                    <em
                                                                        role="button"
                                                                        className="far fa-solid fa-2x fa-rectangle-xmark"
                                                                        style={{ position: "absolute" }}
                                                                        onClick={e => {
                                                                            setScreenImage("")
                                                                            editPerkForm.setFieldValue("image", "")
                                                                        }}
                                                                    />
                                                                </div>
                                                                : <div className=""  {...getRootProps()}>
                                                                    <input {...getInputProps()} />
                                                                    {isDragActive
                                                                        ?
                                                                        <div className='d-flex justify-content-center align-items-center border border-3 border-dashed rounded-3' style={{ width: "350px", height: "350px", backgroundColor: "whitesmoke" }}>
                                                                            <h6>Drop the image here...</h6>
                                                                        </div>
                                                                        : <div className='d-flex justify-content-center align-items-center border border-3 border-dashed rounded-3 flex-column p-2'>
                                                                            {/* <img className="imgUploadIcon" src="images/Image.png" alt="user-avatar" /> */}
                                                                            <div className="btn uploadTxt">
                                                                                <em className="icon ni ni-upload" /> Upload Image
                                                                            </div>
                                                                            <div className="dz-message" data-dz-message="">
                                                                                <span className="dz-message-or">Select or drag & drop onboarding screen image here.</span><br />
                                                                                <span className="dz-message-text">File Format jpeg, png Accepted Size 1000x1000</span>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {editPerkForm.touched.image && editPerkForm.errors.image
                                                                        ? <div className="invalid-feedback" style={{ display: "block" }}>
                                                                            {editPerkForm.errors.image}
                                                                        </div>
                                                                        : ''
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                        {btnLoading
                                                            ? <div className='flex justify-center items-center'>
                                                                <Oval
                                                                    height="30"
                                                                    width="30"
                                                                    color='var(--ps-main)'
                                                                    secondaryColor="var(--ps-main)"
                                                                    ariaLabel='oval-loading'
                                                                    strokeWidth={4}
                                                                    strokeWidthSecondary={4}
                                                                    visible={true}
                                                                />
                                                            </div>
                                                            : <button type="submit px-5" className="btn btn-primary d-inline w-100">Submit</button>
                                                        }
                                                    </div>

                                                </form>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            }
        </>
    );
}

export default EditPerks;
