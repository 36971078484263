import React, { useEffect, useState } from 'react';
import { Oval } from 'react-loader-spinner'
import toast, { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Container from '../Layouts/Container';
import moment from 'moment-timezone';
import { productListAPI } from '../../API/manageProducts';
import { sessionExpire } from '../../Scripts/Helper';
import { logoutAdmin } from '../../Action/authAction';

const Products = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const user = useSelector((state) => state.authReducer.authData)
    const authToken = useSelector((state) => state.authReducer.token)

    const [spinnerLoading, setSpinnerLoading] = useState(false)
    const [navTabs, setNavTabs] = useState({ all: true, active: false, draft: false, archived: false })

    const [productData, setProductData] = useState([])
    const [searchPost, setSearchPost] = useState('')
    const [filterPost, setFilterPost] = useState('')
    const [sortBy, setSortBy] = useState("")
    const [orderBy, setOrderyBy] = useState("")
    const [nextPage, setNextPage] = useState("")

    const fetchProductData = async () => {
        setSpinnerLoading(true)
        const config = {
            headers: { Authorization: `Bearer ${authToken}` }
        }
        await productListAPI(config, filterPost, searchPost, nextPage, sortBy, orderBy)
            .then((response) => {

                setProductData(response ? response.data ? response.data.data : [] : [])

            }).catch((err) => {
                console.log("🚀 ~ file: Profile.jsx:80 ~ err:", err)

                if (err.code == "ERR_NETWORK" || err.code == "ECONNABORTED") {
                    toast.error("Something went wront! please try again later")
                }
                else {
                    let res = sessionExpire(err)
                    toast.error(res.message)

                    if (res.status) {
                        dispatch(logoutAdmin())
                        navigate('/login')
                    }
                }
            })
        setSpinnerLoading(false)
    }

    useEffect(() => {
        !user && navigate('/login')
        fetchProductData()
    }, [filterPost, searchPost, nextPage, sortBy, orderBy]);

    return (
        <>

            {user &&
                <Container>
                    <Toaster position="top-right" reverseOrder={false} />

                    <div className="nk-content mt-5 ">
                        <div className="container-fluid">
                            <div className="nk-content-inner">
                                <div className="nk-content-body">

                                    <div className="nk-block-head nk-block-head-sm mt-3">
                                        <div className="nk-block-between">
                                            <div className="nk-block-head-content">
                                                <h4 className="nk-block-title page-title">The Pool Store /
                                                    <span>Manage produts</span>
                                                </h4>
                                            </div>{/* .nk-block-head-content */}
                                            <div className="d-flex">
                                                <div className="drodown">
                                                    <a href="#" className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white exportDrop" data-bs-toggle="dropdown">Export </a>
                                                    <div className="dropdown-menu dropdown-menu-end">
                                                        <ul className="link-list-opt no-bdr">
                                                            <li><a href="#"><span>On Hold</span></a></li>
                                                            <li><a href="#"><span>Delivered</span></a></li>
                                                            <li><a href="#"><span>Rejected</span></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>{/* .nk-block-between */}
                                    </div>{/* .nk-block-head */}

                                    <div className="nk-block">
                                        <div className="row g-gs">
                                            <div className="col-md-6">
                                                <div className="circleChartFlex">
                                                    <div className="">
                                                        <span>Products summary</span>
                                                        <h6>Showing all {parseInt(productData?.counts?.all)} products</h6>
                                                    </div>
                                                    <div className="d-flex">
                                                        <a href="" className="thiryday">30 Days</a>
                                                        <div className="drodown">
                                                            <a href="#" className="dropdown-toggle btn btn-outline-light btn-white filterBnt" data-bs-toggle="dropdown" aria-expanded="false">Filter
                                                                <em className="icon ni ni-filter-alt"></em></a>
                                                            <div className="dropdown-menu dropdown-menu-end">
                                                                <ul className="link-list-opt no-bdr">
                                                                    <li>
                                                                        <a onClick={e => { setSortBy('created_at'); setNextPage("") }}>
                                                                            <input className='me-2' type="radio" checked={sortBy == "created_at"} onChange={e => { setSortBy('created_at'); setNextPage("") }} name="" id="" />
                                                                            <label htmlFor="">Created</label>
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a onClick={e => { setSortBy('updated_at'); setNextPage("") }}>
                                                                            <input className='me-2' type="radio" checked={sortBy == "updated_at"} onChange={e => { setSortBy('updated_at'); setNextPage("") }} name="" id="" />
                                                                            <label htmlFor="">Updated</label>
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a onClick={e => { setSortBy('total_spent'); setNextPage("") }}>
                                                                            <input className='me-2' type="radio" checked={sortBy == "total_spent"} onChange={e => { setSortBy('total_spent'); setNextPage("") }} name="" id="" />
                                                                            <label htmlFor="">Amount spent</label>
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a onClick={e => { setSortBy('orders_count'); setNextPage("") }}>
                                                                            <input className='me-2' type="radio" checked={sortBy == "orders_count"} onChange={e => { setSortBy('orders_count'); setNextPage("") }} name="" id="" />
                                                                            <label htmlFor="">Total orders</label>
                                                                        </a>
                                                                    </li>
                                                                    <hr style={{ margin: "5px 0px" }} />
                                                                    <li>
                                                                        <a onClick={e => { setOrderyBy('asc'); setNextPage("") }} className={orderBy == 'asc' && 'text-primary'}>
                                                                            <em className="icon ni ni-arrow-up"></em>
                                                                            <label htmlFor="">A - Z</label>
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a onClick={e => { setOrderyBy('desc'); setNextPage("") }} className={orderBy == 'desc' && 'text-primary'}>
                                                                            <em className="icon ni ni-arrow-down"></em>
                                                                            <label htmlFor="">Z - A</label>
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-control-wrap searchBarTable">
                                                    <div className="form-icon form-icon-right">
                                                        <em className="icon ni ni-search"></em></div>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="fv-email"
                                                        value={searchPost}
                                                        onChange={e => setSearchPost(e.target.value)}
                                                        name="fv-email"
                                                        placeholder='Search Here...'
                                                    />
                                                </div>
                                            </div>

                                        </div>{/* .row */}
                                    </div>

                                    <div className="nk-block">
                                        <div className="row g-gs">
                                            <div className="col-md-9">
                                                <ul className="nav nav-tabs mt-n3 brBtmNone" role="tablist">
                                                    <li className="nav-item" role="presentation"
                                                        onClick={e => {
                                                            setNavTabs({ all: true });
                                                            setFilterPost("")
                                                        }}>
                                                        <a className={navTabs.all ? "nav-link active" : "nav-link"} data-bs-toggle="tab" href="#tabItem1" aria-selected="true" role="tab">
                                                            All <span className="tableTabsSpan"> {productData?.counts?.all}</span>
                                                        </a>
                                                    </li>
                                                    <li className="nav-item" role="presentation"
                                                        onClick={e => {
                                                            setNavTabs({ active: true });
                                                            setFilterPost("active")
                                                        }}>
                                                        <a className={navTabs.active ? "nav-link active" : "nav-link"} data-bs-toggle="tab" href="#tabItem2" aria-selected="false" role="tab" tabIndex="-1">
                                                            Active <span className="tableTabsSpan"> {productData?.counts?.active}</span>
                                                        </a>
                                                    </li>
                                                    <li className="nav-item" role="presentation"
                                                        onClick={e => {
                                                            setNavTabs({ draft: true });
                                                            setFilterPost("draft")
                                                        }}>
                                                        <a className={navTabs.draft ? "nav-link active" : "nav-link"} data-bs-toggle="tab" href="#tabItem3" aria-selected="false" role="tab" tabIndex="-1">
                                                            Draft <span className="tableTabsSpan"> {productData?.counts?.draftCounts}</span>
                                                        </a>
                                                    </li>
                                                    <li className="nav-item" role="presentation"
                                                        onClick={e => {
                                                            setNavTabs({ archived: true });
                                                            setFilterPost("archived")
                                                        }}>
                                                        <a className={navTabs.archived ? "nav-link active" : "nav-link"} data-bs-toggle="tab" href="#tabItem4" aria-selected="false" role="tab" tabIndex="-1">
                                                            Archived <span className="tableTabsSpan"> {productData?.counts?.archived}</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="col-md-3">
                                                <Link to={"/add-products"} className="btn btn-primary lightBlue d-md-inline-flex fRight">Add New Product</Link>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-md-12">
                                        <div className="tab-content">

                                            <div className={navTabs.all ? "tab-pane active show" : "tab-pane"} id="tabItem1" role="tabpanel">
                                                {spinnerLoading
                                                    ? <div className='flex justify-center items-center' style={{ height: "50vh" }}>
                                                        <Oval
                                                            height="100"
                                                            width="100"
                                                            color='var(--ps-main)'
                                                            secondaryColor="var(--ps-main)"
                                                            ariaLabel='oval-loading'
                                                            strokeWidth={4}
                                                            strokeWidthSecondary={4}
                                                            visible={true}
                                                        />
                                                    </div>
                                                    :
                                                    <div className="card card-preview">
                                                        <table className="table table-orders mt-3">
                                                            <thead className="tb-odr-head dashboardTableHead">
                                                                <tr className="tb-odr-item">
                                                                    <th className="tb-odr-info"><span className="tb-odr-id">Product Image</span></th>

                                                                    <th className="tb-odr-amount"><span className="tb-odr-total">Product Name </span></th>

                                                                    <th className="tb-odr-amount"><span className="tb-odr-status">Vendor</span></th>

                                                                    <th className="tb-odr-amount"><span className="tb-odr-status">Status</span></th>

                                                                    <th className="tb-odr-amount"><span className="tb-odr-status">Inventory</span></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="tb-odr-body whatSnewTable">
                                                                {productData?.products?.length > 0 &&
                                                                    productData.products.map(data => {
                                                                        return <tr key={data.id} className="tb-odr-item">
                                                                            {/* <td className="tb-odr-info"><em className="icon ni ni-copy"></em> #TPS0965</td> */}
                                                                            <td className="tb-odr-info">
                                                                                <img src={data.productImage} style={{ width: "60px" }} alt="" />
                                                                            </td>
                                                                            <td className="tb-odr-info">{data.title}</td>
                                                                            <td className="tb-odr-info">{data.vendor}</td>
                                                                            <td className="tb-odr-info">
                                                                                <span className="badge rounded-pill badge-dim bg-outline-success">{data.status}</span>
                                                                            </td>
                                                                            <td className="tb-odr-info">{data.inventory_quantity} in Stock</td>
                                                                        </tr>
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>

                                                        {productData.products?.length === 0 &&
                                                            <div className="d-flex justify-content-center align-item-center my-5">
                                                                <h6>No Product Available</h6>
                                                            </div>
                                                        }

                                                    </div>
                                                }
                                            </div>

                                            <div className={navTabs.active ? "tab-pane active show" : "tab-pane"} id="tabItem2" role="tabpanel">
                                                {spinnerLoading
                                                    ? <div className='flex justify-center items-center' style={{ height: "50vh" }}>
                                                        <Oval
                                                            height="100"
                                                            width="100"
                                                            color='var(--ps-main)'
                                                            secondaryColor="var(--ps-main)"
                                                            ariaLabel='oval-loading'
                                                            strokeWidth={4}
                                                            strokeWidthSecondary={4}
                                                            visible={true}
                                                        />
                                                    </div>
                                                    : <div className="card card-preview">
                                                        <table className="table table-orders mt-3">
                                                            <thead className="tb-odr-head dashboardTableHead">
                                                                <tr className="tb-odr-item">
                                                                    <th className="tb-odr-info"><span className="tb-odr-id">Product ID</span></th>

                                                                    <th className="tb-odr-amount"><span className="tb-odr-total">Product Name </span></th>

                                                                    <th className="tb-odr-amount"><span className="tb-odr-status">Vendor</span></th>

                                                                    <th className="tb-odr-amount"><span className="tb-odr-status">Status</span></th>

                                                                    <th className="tb-odr-amount"><span className="tb-odr-status">Inventory</span></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="tb-odr-body whatSnewTable">
                                                                {productData?.products?.length > 0 &&
                                                                    // productData.products.filter(i => i.status == "active").map(data => {
                                                                    productData.products.map(data => {
                                                                        return <tr key={data.id} className="tb-odr-item">
                                                                            {/* <td className="tb-odr-info"><em className="icon ni ni-copy"></em> #TPS0965</td> */}
                                                                            <td className="tb-odr-info">
                                                                                <img src={data.productImage} style={{ width: "60px" }} alt="" />
                                                                            </td>
                                                                            <td className="tb-odr-info">{data.title}</td>
                                                                            <td className="tb-odr-info">{data.vendor}</td>
                                                                            <td className="tb-odr-info">
                                                                                <span className="badge rounded-pill badge-dim bg-outline-success">Active</span>
                                                                            </td>
                                                                            <td className="tb-odr-info">{data.inventory_quantity} in Stock</td>
                                                                        </tr>
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>

                                                        {productData.products?.length === 0 &&
                                                            productData.products.length === 0 &&
                                                            <div className="d-flex justify-content-center align-item-center my-5">
                                                                <h6>No Product Available</h6>
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                            </div>

                                            <div className={navTabs.draft ? "tab-pane active show" : "tab-pane"} id="tabItem3" role="tabpanel">
                                                {spinnerLoading
                                                    ? <div className='flex justify-center items-center' style={{ height: "50vh" }}>
                                                        <Oval
                                                            height="100"
                                                            width="100"
                                                            color='var(--ps-main)'
                                                            secondaryColor="var(--ps-main)"
                                                            ariaLabel='oval-loading'
                                                            strokeWidth={4}
                                                            strokeWidthSecondary={4}
                                                            visible={true}
                                                        />
                                                    </div>
                                                    : <div className="card card-preview">
                                                        <table className="table table-orders mt-3">
                                                            <thead className="tb-odr-head dashboardTableHead">
                                                                <tr className="tb-odr-item">
                                                                    <th className="tb-odr-info"><span className="tb-odr-id">Product ID</span></th>

                                                                    <th className="tb-odr-amount"><span className="tb-odr-total">Product Name </span></th>

                                                                    <th className="tb-odr-amount"><span className="tb-odr-status">Vendor</span></th>

                                                                    <th className="tb-odr-amount"><span className="tb-odr-status">Status</span></th>

                                                                    <th className="tb-odr-amount"><span className="tb-odr-status">Inventory</span></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="tb-odr-body whatSnewTable">
                                                                {productData?.products?.length > 0 &&
                                                                    // productData.products.filter(i => i.status == "draft").map(data => {
                                                                    productData.products.map(data => {
                                                                        return <tr key={data.id} className="tb-odr-item">
                                                                            {/* <td className="tb-odr-info"><em className="icon ni ni-copy"></em> #TPS0965</td> */}
                                                                            <td className="tb-odr-info"  >
                                                                                <img src={data.productImage} style={{ width: "60px" }} alt="" />
                                                                            </td>
                                                                            <td className="tb-odr-info">{data.title}</td>
                                                                            <td className="tb-odr-info">{data.vendor}</td>
                                                                            <td className="tb-odr-info">
                                                                                <span className="badge rounded-pill badge-dim bg-outline-success">Draft</span>
                                                                            </td>
                                                                            <td className="tb-odr-info">{data.inventory_quantity} in Stock</td>
                                                                        </tr>
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>

                                                        {productData.products?.length === 0 &&
                                                            productData.products.length === 0 &&
                                                            <div className="d-flex justify-content-center align-item-center my-5">
                                                                <h6>No Product Available</h6>
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                            </div>

                                            <div className={navTabs.archived ? "tab-pane active show" : "tab-pane"} id="tabItem4" role="tabpanel">
                                                {spinnerLoading
                                                    ? <div className='flex justify-center items-center' style={{ height: "50vh" }}>
                                                        <Oval
                                                            height="100"
                                                            width="100"
                                                            color='var(--ps-main)'
                                                            secondaryColor="var(--ps-main)"
                                                            ariaLabel='oval-loading'
                                                            strokeWidth={4}
                                                            strokeWidthSecondary={4}
                                                            visible={true}
                                                        />
                                                    </div>
                                                    :
                                                    <div className="card card-preview">
                                                        <table className="table table-orders mt-3">
                                                            <thead className="tb-odr-head dashboardTableHead">
                                                                <tr className="tb-odr-item">
                                                                    <th className="tb-odr-info"><span className="tb-odr-id">Product ID</span></th>

                                                                    <th className="tb-odr-amount"><span className="tb-odr-total">Product Name </span></th>

                                                                    <th className="tb-odr-amount"><span className="tb-odr-status">Vendor</span></th>

                                                                    <th className="tb-odr-amount"><span className="tb-odr-status">Status</span></th>

                                                                    <th className="tb-odr-amount"><span className="tb-odr-status">Inventory</span></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="tb-odr-body whatSnewTable">
                                                                {productData?.products?.length > 0 &&
                                                                    // productData.products.filter(i => i.status == "archived").map(data => {
                                                                    productData.products.map(data => {
                                                                        return <tr key={data.id} className="tb-odr-item">
                                                                            {/* <td className="tb-odr-info"><em className="icon ni ni-copy"></em> #TPS0965</td> */}
                                                                            <td className="tb-odr-info">
                                                                                <img src={data.productImage} style={{ width: "60px" }} alt="" />
                                                                            </td>
                                                                            <td className="tb-odr-info">{data.title}</td>
                                                                            <td className="tb-odr-info">{data.vendor}</td>
                                                                            <td className="tb-odr-info">
                                                                                <span className="badge rounded-pill badge-dim bg-outline-success">Archived</span>
                                                                            </td>
                                                                            <td className="tb-odr-info">{data.inventory_quantity} in Stock</td>
                                                                        </tr>
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>

                                                        {productData.products?.length === 0 &&
                                                            productData.products.length === 0 &&
                                                            <div className="d-flex justify-content-center align-item-center my-5">
                                                                <h6>No Product Available</h6>
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                            </div>

                                        </div>
                                    </div>





                                    <div className='d-flex justify-content-between mt-3'>
                                        <button
                                            disabled={productData.previousPageParameters == null}
                                            className="btn btn-primary d-md-inline-flex fRight"
                                            onClick={e => {
                                                // setSortBy("");
                                                // setOrderyBy("");
                                                setNextPage(productData.previousPageParameters.page_info)
                                            }}
                                        >
                                            Previous Page
                                        </button>
                                        <button
                                            disabled={productData.nextPageParameters == null}
                                            className="btn btn-primary d-md-inline-flex fRight"
                                            onClick={e => {
                                                // setSortBy("");
                                                // setOrderyBy("");
                                                setNextPage(productData.nextPageParameters.page_info)
                                            }}
                                        >
                                            Next Page
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </Container>
            }
        </>
    );
}

export default Products;
